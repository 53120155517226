import { Pipe, PipeTransform } from '@angular/core';
import {AppVariables} from '../config/app.variables';
import {AppConfig} from '../config/app.config';

@Pipe({
  name: 'href',
  pure: false
})
/**
 * Zamienia scieżkę na poprawny link tzn uwzględniając np. język.
 */
export class HrefPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (this.isFullLink(value)){
      return value;
    }
    if (AppVariables.Languages) {
      if (AppVariables.Languages.isCurrentLangDefault()) {
        return value
      }else {
        return `/${AppConfig.language}${value[0] === "/" ? value : '/' + value}`
      }
    }
    return value
  }

  isFullLink(value:string): boolean {
    return value.includes('http://') || value.includes('https://')
  }

}
