// @todo RemoveThisMessage. If the message remains, the file could be regenerated!
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {CoreLocale} from './locale.model';
import {Observable} from 'rxjs/Observable';
import {map, catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

import {AppConfig} from '../../../../config/app.config';
import {Filter} from '../../core/utils/filter/filter.model';
import {AppService} from '../../../../core/services/app.service';

@Injectable({
    providedIn: 'root'
})
export class CoreLocaleBaseService {
    request$: EventEmitter<any>;
    getAllCache: Observable<any>;
    protected apiUrl: string;

    constructor(protected http: HttpClient, protected appService: AppService) {
        this.apiUrl = AppConfig.endpoints.serverApi;
        this.request$ = new EventEmitter();
    }

    public getAll(params: string = "", isLd = false): Observable<any> {
        if (!this.getAllCache){
            this.request$.emit('starting');
            let url = `${this.apiUrl}/${AppConfig.language}/core/locales${params}`;
            return this.http.get<CoreLocale[]>(url, this.appService.getOptions(isLd)).pipe(
                map(response => {
                    this.request$.emit('finished');
                    return response;
                }),
                catchError(error => this.handleError(error))
            );
        }

        return this.getAllCache;
    }

    public getByFilter(filter: Filter = null): Observable<any> {
        if (!this.getAllCache) {
            this.request$.emit('starting');
            var url = `${this.apiUrl}/${AppConfig.language}/core/locales`;
            if (filter) {
                url += `?f=${JSON.stringify(filter.filter)}`;
            }
            return this.http.get<CoreLocale[]>(url, this.appService.getOptions()).pipe(
                map(response => {
                    this.request$.emit('finished');
                    return response;
                }),
                catchError(error => this.handleError(error))
            );
        }
        return this.getAllCache;
    }

    public getOne(id: number): Observable<any> {
        this.request$.emit('starting');
        var url = `${this.apiUrl}/${AppConfig.language}/core/locales/${id}`;
        return this.http.get<CoreLocale>(url, this.appService.getOptions()).pipe(
            map(response => {
                this.request$.emit('finished');
                return response;
            }),
            catchError(error => this.handleError(error))
        );
    }

    protected handleError(error: any): Observable<never> {
        this.request$.emit('finished');
        if (error instanceof Response) {
            return throwError(error.json()['error'] || 'backend server error');
        }
        return throwError(error || 'backend server error');
    }
}
