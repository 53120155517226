import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'bundle.list',
  templateUrl: './bundle.list.component.html',
  styleUrls: ['./bundle.list.component.scss'],
  providers: []
})

export class BundleListComponent extends BaseComponent implements OnInit{

    _elementSelektor: string
    _elements

    @Input()
    set elementSelektor(elementSelektor: string) {
      this._elementSelektor = elementSelektor
    }

    @Input()
    set elements(elements) {
      this._data = elements
    }

    ngOnInit() {
    }
}
