<div>
<ng-container *ngIf="page">
  <ng-container *ngFor="let section of page?.contentSections">
    <section class="page-content {{section?.name}}" style="{{generateStyle(section)}}">
        <div *ngFor="let block of section?.blocks" class="block flexbox  wrapper {{block?.name}}" style="justify-content: space-between;{{generateStyle(block)}}">
            <div *ngFor="let slot of block?.slots" class="{{slot?.name}} sCol slot" style="{{generateStyle(slot)}}" >

<!--              {{'bundle.' + convertPlugKey(slot.plugtemplate.plugKey)}}-->
                <app-dynamic-selector [inputs]="{slot: slot}" [componentSelector]="'bundle.' + convertPlugKey(slot.plugtemplate.plugKey)"></app-dynamic-selector>

            </div>
        </div>
    </section>
  </ng-container>
</ng-container>
</div>



<!--<section class="page-content wrapper">-->
<!--  <div class="block">-->
<!--    <div style="">-->
<!--      <app-dynamic-selector [componentSelector]="'bundle.blog.menu'" [moduleLoaderFunction]="getModuleLoader()"></app-dynamic-selector>-->
<!--      <app-dynamic-selector [componentSelector]="'bundle.blog.list'" [moduleLoaderFunction]="getModuleLoader()"></app-dynamic-selector>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->

<!--<section class="page-content" style="background-color: #FBFBFB">-->
<!--  <div class="block">-->
<!--    <div style="">-->
<!--        <app-dynamic-selector [componentSelector]="'bundle.recruitment.list'" [moduleLoaderFunction]="getModuleLoader()"></app-dynamic-selector>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->

<!--<section class="page-content" style="background-color: #1c671c; padding: 20px 0">-->
<!--  <div class="block flexbox wrapper">-->
<!--    <div class="slot" style="flex: 0 0 48%">-->
<!--      <app-dynamic-selector [componentSelector]="'bundle.newsletter.footer'" [moduleLoaderFunction]="getModuleLoader()"></app-dynamic-selector>-->
<!--    </div>-->
<!--    <div class="slot" style="flex: 0 0 48%; color: white">-->
<!--      <app-dynamic-selector [componentSelector]="'bundle.boxh4'" [moduleLoaderFunction]="getModuleLoader()"></app-dynamic-selector>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->




<!--<div *ngIf="page !== undefined">-->
<!--    <section *ngFor="let structureSection of page?.structurePageContentSections; let last = last;" class="page-content {{page.code}}" [ngStyle]="{'background-color': this.setSectionBgColor(structureSection)}" [ngClass]="{'last-page-content': last}">-->
<!--        <div class="flexbox flSt"[ngClass]="{'wrapper': this.sectionFitToPage(structureSection)==false}" [ngStyle]="{'justify-content': this.setSectionJustify(structureSection)}">-->
<!--            <div class="sCol flexbox flSt" *ngFor="let structureBlock of structureSection?.structureSectionBlocks" [ngStyle]="{'background-color': this.setBlockBgColor(structureBlock), 'flex': this.setBlockWidth(structureBlock), 'margin-top.px': this.setBlockMarginTop(structureBlock), 'margin-bottom.px': this.setBlockMarginBottom(structureBlock)}" >-->
<!--              <div *ngFor="let slot of structureBlock?.structureBlockSlots" [ngSwitch]="slot.module+'-'+slot.template"-->
<!--                   [attr.data-animation-type]="this.setAnimation(slot, 'data-type')"-->
<!--                   [attr.data-animation-delay]="this.setAnimation(slot, 'data-delay')"-->
<!--                   [attr.data-animation-duration]="this.setAnimation(slot, 'data-duration')"-->
<!--                   [attr.data-animation-offset]="this.setAnimation(slot, 'data-offset')"-->
<!--                   class="{{slot.module}}-{{slot.template}} {{this.setAnimation(slot, 'class-animated')}}" [ngStyle]="{'flex': this.setSlotWidth(slot)}">-->

<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'slider-slider-main'" class="slider-slider-main {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'box-box-text'" class="box-box-text {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'box-box-text-image'" class="box-box-text-image {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'box-box-logo'" class="box-box-logo {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'settings-owner-data'" class="settings-owner-data {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'box-box-home-text'" class="box-box-home-text" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'vehicles-home'" class="vehicles-home" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'core-breadcrumbs'" class="core-breadcrumbs" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'article-article'" class="article-article {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'news-news-slider'" class="news-news-slider" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'news-news-details'" class="news-news-details" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'news-news'" class="news-news" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bundle *ngSwitchCase="'vehicles-vehicles'" class="vehicles-vehicles {{page.code}}" [slot]="slot"></bundle>&ndash;&gt;-->
<!--&lt;!&ndash;                  <core-owner-data *ngSwitchCase="'settings-owner-data'" class="settings-owner-data" [slot]="slot"></core-owner-data>&ndash;&gt;-->



<!--&lt;!&ndash;                    <bundle *ngSwitchCase="'core-breadcrumbs'" class="core-breadcrumbs" [slot]="slot"></bundle>&ndash;&gt;-->
<!--                    <app-core-nav *ngSwitchCase="'core-nav'" class="core-nav" [slot]="slot" [recursive]="false"></app-core-nav>-->
<!--                    <app-core-sitemap *ngSwitchCase="'core-sitemap'" class="core-sitemap" [slot]="slot"></app-core-sitemap>-->
<!--                    <app-core-nav *ngSwitchCase="'core-nav-recursive'" class="core-nav-recursive" [slot]="slot" [recursive]="true"></app-core-nav>-->
<!--&lt;!&ndash;                    <bundle *ngSwitchCase="'form-form'" class="form-form" [slot]="slot"></bundle>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->

<!--    <footer >-->
<!--        <section *ngFor="let structureSection of page?.structurePageFooterSections" class="page-content" [ngStyle]="{'background-color': this.setSectionBgColor(structureSection)}">-->
<!--            <div class="flexbox flSt" [ngClass]="{'wrapper': this.sectionFitToPage(structureSection)==false}" [ngStyle]="{'justify-content': this.setSectionJustify(structureSection)}">-->
<!--                <div class="sCol flexbox flSt" *ngFor="let structureBlock of structureSection?.structureSectionBlocks" [ngStyle]="{'flex': this.setBlockWidth(structureBlock)}" >-->
<!--                  <div *ngFor="let slot of structureBlock?.structureBlockSlots" [ngSwitch]="slot.module+'-'+slot.template"-->
<!--                       [attr.data-animation-type]="this.setAnimation(slot, 'data-type')"-->
<!--                       [attr.data-animation-delay]="this.setAnimation(slot, 'data-delay')"-->
<!--                       [attr.data-animation-duration]="this.setAnimation(slot, 'data-duration')"-->
<!--                       [attr.data-animation-offset]="this.setAnimation(slot, 'data-offset')"-->
<!--                       class="{{slot.module}}-{{slot.template}} {{this.setAnimation(slot, 'class-animated')}}" [ngStyle]="{'flex': this.setSlotWidth(slot)}">-->
<!--&lt;!&ndash;                    <bundle *ngSwitchCase="'box-footer'" class="box-footer" [slot]="slot"></bundle>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
<!--    </footer>-->

<!--</div>-->
