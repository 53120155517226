<div class="custom-dropdown-wrapper" (click)="active=!active" [ngClass]="{'active':active}"  id="{{randId}}">
  <input type="text" *ngIf="settings?.isSearchable" [(ngModel)]="searchTerm" (keyup)="search()" value="Wybierz rozmiar">
  <span *ngIf="!settings?.isSearchable" class="selected">
    <span class="label" *ngIf="searchTerm !== undefined || searchTerm !== ''">{{searchTerm}}</span>
    <ng-container *ngIf="searchTerm === undefined || searchTerm === ''">&nbsp;</ng-container>
    <ng-container *ngIf="svg != false">
    <app-core-svg [url]="svg || '/assets/staticresource/layout/base/arrow.svg'"></app-core-svg>
    </ng-container>
  </span>
  <div class="dropdown">
    <ul >
      <li class="option" *ngFor="let option of filterOptions;let i = index" (click)="changeValue(option.key)"><span class="text">{{option.value}}</span></li>
    </ul>
  </div>
</div>


<!--<div class="custom-dropdown-wrapper" (click)="active=!active" [ngClass]="{'active':active}" #dropdown>-->
<!--  <input type="text" *ngIf="settings?.isDatalist" [(ngModel)]="searchTerm" (keyup)="search()">-->
<!--  <span *ngIf="!settings?.isDatalist" class="selected">{{selectedItem}}</span>-->
<!--  <span [class]="settings?.caretClass"-->
<!--        class="caret"-->
<!--        [ngStyle]="{'top':positionTop,'right':positionRight}"-->
<!--        [ngClass]="{'icon':!settings?.caretClass}"></span>-->
<!--  <ul class="dropdown">-->
<!--    <li class="option" *ngFor="let option of filterOptions;let i = index" (click)="changeValue(option.key)"><span-->
<!--      class="text">{{option.value}}</span></li>-->
<!--  </ul>-->
<!--</div>-->
