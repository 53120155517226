<div>
  <ng-container *ngIf="message; else loader">
    <app-core-message></app-core-message>
  </ng-container>
  <ng-template #loader>
    Trwa przetwarzanie...

<!--    <bundle class="loader"></bundle>-->
  </ng-template>

</div>
