import {Component, Input} from '@angular/core';
import {CMSPageService} from '../../shared/bundles/cms/page/page.service';
import {AppConfig} from '../../config/app.config';
import {BaseComponent} from '../../bundles/base.component';

@Component({
  selector: 'app-core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: []
})

export class FooterComponent{
  @Input() structureBlock;
  year: number;

  constructor(public structurePageService: CMSPageService) {
    this.year = (new Date()).getFullYear();
  }

  get owner(): any {
    return AppConfig.owner;
  }

  get showRealization(): boolean {
    return AppConfig.showRealization;
  }

  get realization() {
    return AppConfig.realization;
  }
}
