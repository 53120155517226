import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {Injectable} from '@angular/core';
import {AppService} from '../core/services/app.service';
import {AppConfig} from '../config/app.config';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';


@Injectable({
  providedIn: "root"
})
export class CoreImageService extends ApiService{

  protected apiUrl: string;

  getImage(data){
    return this.http.post(this.apiUrl+'/'+AppConfig.language+'/coreimages', data, this.appService.getOptions())
      .pipe(
        map(response => {
          return response;
        })
      )
  }
}
