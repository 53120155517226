import {Component, ComponentRef, ElementRef, Injector, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
// import {StructurePage} from '../../shared/bundles/structure/page/page.model';
import {HttpClient} from '@angular/common/http';
// import {RoutingPageService} from '../../shared/bundles/structure/routing/page.service';
import {CoreLanguageService} from '../../shared/bundles/core/language/language.service';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {AppConfig} from '../../config/app.config';
import {ActivatedRoute, NavigationEnd, Params, Route} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {Router} from '@angular/router';
// import {StructurePageService} from '../../shared/bundles/cms/page/page.service';
import {CMSSlot} from '../../shared/bundles/cms/slot/slot.model';
import {CMSPage} from '../../shared/bundles/cms/page/page.model';
import {AppService} from '../services/app.service';
import {RoutingPageService} from '../../services/routing.page.service';
import {map} from 'rxjs/operators';
import {DynamicComponentService} from '../../services/dynamic.component.service';
import {ConverterService} from '../../services/converter.service';
import {UrlService} from '../../services/urlService';
import {BaseComponent} from '../../bundles/base.component';
// import {StructureSection} from "../../shared/bundles/structure/section/section.model";
// import {StructureBlock} from "../../shared/bundles/structure/block/block.model";
// import {StructureSlot} from "../../shared/bundles/structure/slot/slot.model";



@Component({
    selector: 'app-core-contentsection',
    templateUrl: './contentsection.component.html',
    styleUrls: ['./contentsection.component.scss'],
    providers: []
})

export class ContentsectionComponent extends BaseComponent implements OnInit{
    @Input() structureBlock;

    // @ViewChild()
    // @ViewChildren('slotContainer') attendeeInputs: QueryList<ElementRef>;
    // slotContainer

    page: CMSPage;

    constructor(protected _injector: Injector,

                private http: HttpClient,
                private routingPageService: RoutingPageService,
                private languageService: CoreLanguageService,
                private route: ActivatedRoute,
                private urlP: LocationStrategy,
                private _router: Router,
                // private componentService: DynamicComponentService,
                // protected appService: AppService,
                // protected converter: ConverterService,
                // protected urlService: UrlService,
                // public structurePageService: StructurePageService
    ) {
        // super(structurePageService);
      super(_injector);
    }

    ngOnInit() {
      this.converter.converterMap = this.route.snapshot.data['converters'];
      AppConfig.structurePageList = this.route.snapshot.data['pages'];

      this.urlService.url.subscribe(url => {
        let urlForStructurePage = this.urlService.getUrlWithoutLang();
        // console.log('urlForStructurePage', urlForStructurePage)
        if (urlForStructurePage) {
          this.getPageStructure(urlForStructurePage);
        }
      });

      this.urlService.changeUrl(window.location.pathname + window.location.search)
      this._router.events.subscribe(ev => {
        if(ev instanceof NavigationEnd) {
          this.urlService.changeUrl(ev.url);
        }
      });
    }

    getPageStructure(url): void {
      console.log(url);
      // if (url === "/"){
        let body = document.getElementsByTagName('body')[0];

        if(body){
          if(url != "/"){
            body.classList.add('subpage');
          }else{
            body.classList.remove('subpage');
          }
        }
      // }
      this.routingPageService.get(url).subscribe((page: CMSPage) => {
        console.log('page',page)
        if (page && page.redirectToType === 'insideUrl' && page.redirectToUrl) {
          this._router.navigateByUrl(page.redirectToUrl);
        }
        if (page && page.redirectToType === 'file' && page.redirectToUrl) {
          window.location.replace(page.redirectToUrl);
        }
        if (page && page.redirectToType === 'outsideUrl' && page.redirectToUrl) {
          window.location.replace(page.redirectToUrl);
        }
        this.page = page;
        if (this.page) {
          // this.setMetadata(this.page.structurePageSeoMetaData || null);
        }
      });
    }

    generateStyle(element): string {
      let retval = "";
      if(element.cssOptions) {
        let cssPropsArr = [];
        element.cssOptions.elements.forEach(element => {
          let o = {};
          for(let elementKey in element){
            if(elementKey[0] !="@"){
              o[elementKey] = element[elementKey];
            }
          }
          cssPropsArr.push(o);
        });
        retval = cssPropsArr.filter(el => el.prop).map(el => `${el.prop}: ${el.val};`).join("")
      }
      return retval;
    }

    public convertPlugKey(plugKey: string): string {
      return plugKey.replace(/\./g, "-");
    }

    // setAnimation(slot: StructureSlot, type) {
    //   var result = '';
    //
    //   if (slot.structureSlotAnimation) {
    //     if(type === 'data-type') {
    //       result = slot.structureSlotAnimation.aType;
    //     }
    //     if(type === 'data-duration') {
    //       result = slot.structureSlotAnimation.aDuration.toString();
    //     }
    //     if(type === 'data-delay') {
    //       result = slot.structureSlotAnimation.aDelay.toString();
    //     }
    //     if(type === 'data-offset' && slot.structureSlotAnimation.runTopOffset) {
    //       result = slot.structureSlotAnimation.runTopOffset.toString();
    //     }
    //     if(type === 'class-animated') {
    //       if (slot.structureSlotAnimation.isRunIfVisible) {
    //         result = 'wait_animated';
    //       }
    //       else {
    //         result =  'animated' + ' '  + slot.structureSlotAnimation.aDuration + ' ' + slot.structureSlotAnimation.aType + ' db-' + slot.structureSlotAnimation.aDelay;
    //       }
    //     }
    //   }
    //   return result;
    // }
}
