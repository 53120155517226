<div class="message {{message?.type}} " [ngClass]="(message?.flash==true)?'flash':''">
    <ng-container *ngIf="message?.type == 'mask'">
        <div class="loaderComponent mask">
            <div class="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="message?.type == 'loader'">
        <div class="loaderComponent">
            <div class="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="message?.type == 'loaderBig'">
        <div class="loaderComponent big">
            <div class="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="message?.type != 'loader'">
        <span class="title" *ngIf="message?.header">{{message?.header|trans}}</span>
        <ng-container *ngIf="message?.header && message?.content"><span class="separator"><!-- --></span></ng-container>
        <div class="content" *ngIf="message?.content" innerHtml="{{message?.content|trans}}"></div>
    </ng-container>
</div>

