import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {BaseComponent} from "../../base.component";


@Component({
    selector: 'bundle.reservation-progressbar',
    templateUrl: './bundle.reservation.progressbar.component.html',
    styleUrls: ['./bundle.reservation.progressbar.component.scss'],
})

export class BundleReservationProgressbarComponent extends BaseComponent implements OnInit {
    @Input() step;
    @Input() stepCounter;

    ngOnInit() {

    }

}
