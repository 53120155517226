<ng-container *ngIf="paginationArr.length > 0">
<div class="main-container">
  <div class="pagination">
    <div class="flexbox pager-container">
        <app-core-svg [url]="'/assets/staticresource/layout/next.svg'" (click)="prevPage()"
                      [ngClass]="{'disabled': currentPage <= 1}"></app-core-svg>

        <div class="pages flexbox">
          <ng-container *ngFor="let el of paginationArr">
            <ng-container *ngIf="isNumber(el); else staticElementPager">
              <div class="page-number" [ngClass]="{'active': el == currentPage}" (click)="changePage(el)">
                {{el}}
              </div>
            </ng-container>
            <ng-template #staticElementPager>
              <div class="page-number-static">{{el}}</div>
            </ng-template>
          </ng-container>
        </div>

        <app-core-svg [url]="'/assets/staticresource/layout/next.svg'" (click)="nextPage()"
                      [ngClass]="{'disabled': currentPage >= getAmountOfPages()}"></app-core-svg>
    </div>
  </div>
</div>
</ng-container>
