import {ComponentFactoryResolver, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {throwIfAlreadyLoaded} from './module-import-guard';
import { RouterModule } from '@angular/router';
import {BaseModule} from '../services/base-module';

// import {BundleBlogListComponent} from './list/blog/bundle.blog.list.component';
// import {BundleBlogMenuComponent} from './list/blogMenu/bundle.blog.menu.component';


import {ContentsectionComponent} from '../core/contentsection/contentsection.component';
import {BreadcrumbsComponent} from '../core/breadcrumbs/breadcrumbs.component';
import {LanguagesComponent} from '../core/languages/languages.component';
import {CookiepopupComponent} from '../core/cookiepopup/cookiepopup.component';
import {FooterComponent} from '../core/footer/footer.component';
import {HeaderComponent} from '../core/header/header.component';
import {NavComponent} from '../core/nav/nav.component';
import {SvgComponent} from '../core/svg/svg.component';
import {DynamicSelectorComponent} from '../services/dynamic.selector.component';
import {ImageComponent} from '../core/image/image.component';
import {TransPipe} from '../pipes/trans.pipe';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {BundleNewsletterFooterComponent} from './newsletter/newsletter.footer/bundle.newsletter.footer.component';

import {BundleListCenterComponent} from './list/list-center/bundle.list-center.component';

import {BundleCategoryNavComponent} from './nav/category-nav/bundle.category.nav.component';

import {BundleFormFormComponent} from './form/form/bundle.form.form.component';
import {BundleFormCustomselectComponent} from './form/customselect/bundle.form.customselect.component';
import {BundleFooterNavComponent} from './nav/footer-nav/bundle.footer.nav.component';
import {BundleListComponent} from './list/list/bundle.list.component';
import {HrefPipe} from '../pipes/href.pipe';
import {IsUrlVisitPipe} from '../pipes/isUrlVisit.pipe';
import {EnumeratePipe} from '../pipes/enumarte.pipe';
import {PaginationComponent} from '../core/pagination/pagination.component';
import {BundleBoxComponent} from './box/box/bundle.box.component';
import {BundleNewsCardComponent} from "./news/card/bundle.news-card.component";
import {BundleSlickComponent} from "./components/slick/bundle.slick.component";
import {BundleNewsListSlickComponent} from "./news/news-list-slick/bundle.news-list-slick.component";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {LinkPipe} from "../pipes/link.pipe";
import {BundleReservationReservationComponent} from "./reservation/reservation/bundle.reservation.reservation.component";
import {BundleReservationManageComponent} from "./reservation/manage/bundle.reservation.manage.component";
import {BundleReservationPlaceComponent} from "./reservation/place/bundle.reservation.place.component";
import {BundleReservationFormComponent} from "./reservation/form/bundle.reservation.form.component";
import {BundleReservationProgressbarComponent} from "./reservation/progressbar/bundle.reservation.progressbar.component";
import {BundleBoxContactComponent} from "./box/contact/bundle.box.contact.component";
import {BundleArticleArticleComponent} from "./article/article/bundle.article.article.component";
import {RatingComponent} from "../core/rating/rating.component";
import {BundleOpinionComponent} from "./opinions/opinion/bundle.opinion.component";
import {MediaComponent} from "../core/media/media.component";
import {IsImgPipe} from "../pipes/isImg.pipe";
import {IsSvgPipe} from "../pipes/isSvg.pipe";
import {BundleGallerySlickComponent} from "./gallery/gallery-slick/bundle.gallery.slick.component";
import {BundleGalleryCardComponent} from "./gallery/card/bundle.gallery.card.component";
import {BundleConfirmationComponent} from "./components/confirmation/bundle.confirmation.component";
import {MessageComponent} from "../core/message/message.component";
import {BundleLoaderComponent} from "../core/loader/loader.component";



const components = [

  BundleNewsletterFooterComponent,
  BundleBoxComponent,

  BundleListCenterComponent,

  BundleCategoryNavComponent,
  BundleFormFormComponent,
  BundleFormCustomselectComponent,


  BundleFooterNavComponent,

  BundleListComponent,

  BundleNewsCardComponent,
  BundleNewsListSlickComponent,

  BundleSlickComponent,

  BundleReservationReservationComponent,
  BundleReservationManageComponent,
  BundleReservationPlaceComponent,
  BundleReservationFormComponent,
  BundleReservationProgressbarComponent,
  BundleBoxContactComponent,
  BundleArticleArticleComponent,
  BundleOpinionComponent,
  BundleGallerySlickComponent,
  BundleGalleryCardComponent,

  BundleConfirmationComponent

];

const coreComponents = [
    ContentsectionComponent,
    BreadcrumbsComponent,
    CookiepopupComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    SvgComponent,
    LanguagesComponent,
    DynamicSelectorComponent,
    ImageComponent,
    PaginationComponent,
    RatingComponent,
  MediaComponent,
  MessageComponent,
  BundleLoaderComponent
];

const pipes = [
  TransPipe,
  SafeHtmlPipe,
  HrefPipe,
  IsUrlVisitPipe,
  EnumeratePipe,
  LinkPipe,
  IsImgPipe,
  IsSvgPipe,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SlickCarouselModule
  ],
    exports: [...components, ...coreComponents, ...pipes],
    declarations: [...components, ...coreComponents, ...pipes],
    providers: []
})

export class BundlesModule extends BaseModule{

  dynamicComponents = [...components, ...coreComponents];

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
}
