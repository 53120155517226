import { Pipe, PipeTransform } from '@angular/core';
import {AppVariables} from '../config/app.variables';
import {AppConfig} from '../config/app.config';
import {UrlService} from "../services/urlService";
import {didIsImgFromString, didIsSvg, link} from "../utils/utils";

@Pipe({
  name: 'isSvg',
  pure: false
})

export class IsSvgPipe implements PipeTransform {

  constructor(protected urlService: UrlService) {}

  transform(value: string, slug: string = "", cmsid: string = ''): boolean {
    return didIsSvg(value);
  }
}
