<div class="progressbar">
    <div class="top">
        <div >
            <span [innerHtml]="'reservation_progressbar_step_1_description'|trans"></span>
        </div>
        <div>
            <span [innerHtml]="'reservation_progressbar_step_2_description'|trans"></span>
        </div>
        <div>
            <span [innerHtml]="'reservation_progressbar_step_3_description'|trans"></span>
        </div>
        <div>
            <span [innerHtml]="'reservation_progressbar_step_4_description'|trans"></span>
        </div>
    </div>
    <div>
        <div >
            <span class="step" [ngClass]="{'active': step>=1}">
                <!--1 {{getLocale('reservation_step')}}-->
            </span>
        </div>
        <div>
            <span class="step" [ngClass]="{'active': step>=2}">
                <!--2 {{getLocale('reservation_step')}}-->
            </span>
        </div>
        <div>
            <span class="step" [ngClass]="{'active': step>=3}">
                <!--3 {{getLocale('reservation_step')}}-->
            </span>
        </div>
        <div>
            <span class="step" [ngClass]="{'active': step>=4}">
                <!--4 {{getLocale('reservation_step')}}-->
            </span>
        </div>
    </div>
</div>
