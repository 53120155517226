import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {AppPlaceService} from "../../../shared/bundles/app/place/place.service";
import {AppReservation} from "../../../shared/bundles/app/reservation/reservation.model";
import {AppPlace} from "../../../shared/bundles/app/place/place.model";

// import {BaseComponent} from '../../../core/base.component';
// import {StructurePageService} from '../../../shared/bundles/structure/page/page.service';
// import {ReservationCategoryService} from '../../../shared/bundles/reservation/category/category.service';
// import {BoxBox} from '../../../shared/bundles/box/box/box.model';
// import {ReservationPlaceService} from '../../../shared/bundles/reservation/place/place.service';
// import {ReservationPlace} from '../../../shared/bundles/reservation/place/place.model';
// import {ReservationTestify} from '../../../shared/bundles/reservation/testify/testify.model';

@Component({
  selector: 'bundle.reservation-place',
  templateUrl: './bundle.reservation.place.component.html',
  styleUrls: ['./bundle.reservation.place.component.scss'],
})

export class BundleReservationPlaceComponent implements OnInit {

  places: any;

  constructor(private placeService: AppPlaceService) {
  }

  ngOnInit() {
    this.placeService.getAll().subscribe((places: Array<AppPlace>) => {
      this.places = places.slice(0,2);
    });
  }

}
