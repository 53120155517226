import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

import {CoreMessage} from '../../../services/CoreMessage';
import {FormBaseComponent} from "../../form.base.component";



@Component({
  selector: 'bundle.form',
  templateUrl: './bundle.form.form.component.html',
  styleUrls: ['./bundle.form.form.component.scss'],
  providers: []
})

export class BundleFormFormComponent extends FormBaseComponent {
  files: any;
  // message: CoreMessage;
  messageNoOptions: CoreMessage;

  @Output()
  submitEvent = new EventEmitter()

  submitPost($form, $event, formvalue) {
    if (this.submit && typeof this.submit === "function"){
      this.submit.call(this, $form, $event, formvalue)
    }else {
      $event.preventDefault();
      this.formService.clearFormMessage(this.formForm.formKey);
      console.log(this.urlSend);


      this.formService.send(this.prepareData(new FormData($event.target)), this.urlSend).subscribe(response => {
        this.formAfterSend(response)
      })
    }
  }

  prepareData(formFd) {
    return formFd;
  }

  formAfterSend(response) {
    let result = this.formService.retriveResponse(this.formForm.formKey, response, false, true);
    if (result instanceof CoreMessage) {
      this.message = result;
    } else {
      this.forValidationErrorList = result;
    }
    this.submitEvent.emit(response);
    return result
  }

}
