import {Component, Input, OnInit} from '@angular/core';
// import {Filter} from '../../shared/bundles/core/utils/filter/filter.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {CMSPageService} from '../../shared/bundles/cms/page/page.service';
import {AppConfig} from '../../config/app.config';
import {getLocale} from '../../utils/utils';


import {CMSPage} from '../../shared/bundles/cms/page/page.model';
import {ApiService} from '../../services/api.service';
import {UrlService} from '../../services/urlService';
// import {StructurePage} from '../../shared/bundles/structure/page/page.model';

// import {StructureNavService} from '../../shared/bundles/structure/nav/nav.service';
// import {StructureNav} from '../../shared/bundles/structure/nav/nav.model';

@Component({
    selector: 'app-core-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    providers: []
})

export class NavComponent implements OnInit {

    @Input() menu: any;
    @Input() menuAll: any;
    @Input() parentId: number;
    @Input() forcedElementId: number;
    @Input() slot;
    @Input() recursive: boolean;
    @Input() logo = false;
    @Input() owner: any;
    @Input() isSubmenu = false;
    @Input() logoAsXelem = 0;
    //

    protected currentUrl = "";

    constructor(public structurePageService: CMSPageService,
                // private navService: Nav,
                private apiService: ApiService,
                protected urlService: UrlService,
                // private router: Router,
                private route: ActivatedRoute) {
        // super(structurePageService);
    }
    //
    ngOnInit(): void {
      // if(!this.menu) {
      //   this.apiService.getByStaticUrl(AppConfig.endpoints.serverApi + '/pl/cms/menu_elements.json?parent=1').subscribe((menu: any) => {
      //     this.menu = menu.sort((a, b) => {
      //       return a.sortPosition - b.sortPosition;
      //     });
      //
      //     console.log('menu', menu)
      //   });
      // }

      // var lvl = 100000;
        // var menuTmp: StructureNav[] = [];
        // if (this.slot || this.forcedElementId) {
            // var filter = new Filter();
            // if (this.slot && this.slot.element) {
            //     filter.addWhere('id', '=', this.slot.element);
            // }
            // if (this.forcedElementId) {
            //     filter.addWhere('id', '=', this.forcedElementId);
            // }
            // setTimeout((function(){

            // }), 500);
        // }
      this.urlService.url.subscribe((url:string) => {
        this.currentUrl = url;
      })
    }


    // getListByParentId(parentId: number): any[] {
    //     return this.menuAll.filter((subject) => subject.parentId === parentId);
    // }

    didLinkIsActive(menuItem: CMSPage): boolean {
      // console.log('menuItem',menuItem)
      return this.currentUrl.toLowerCase().includes(menuItem.url.toLowerCase());
        // if (this.getListByParentId(menuItem.id).length > 0 && this.recursive) {
        //     var actual = this.getCurrentRouteLink() + '/';
        //     var substr = actual.substring(menuItem.url.length, 0);
        // }
    }
    //
    getCurrentRouteLink(): string {
        var rl = '/pl/blog';
        return rl;
    }
    //
    // navigateToUrl(url: string): void {
    //     this.router.navigate(url.split('/'));
    // }
    //
    // // funkcja dubluje metodę z hedera ale na chwilę obecną nie ma czasu tego robić lepiej
    // // TODO: naprawić
    toggleMobileMenu(): void {
        var list = document.getElementsByTagName('header');
        if(list[0]) {
            if (list[0].classList.contains('opened'))
            {
                list[0].classList.remove('opened');
            }else{
                list[0].classList.add('opened');
            }
        }
    }

}
