import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";

@Component({
    selector: 'bundle.gallery-card',
    templateUrl: './bundle.gallery.card.component.html',
    styleUrls: ['./bundle.gallery.card.component.scss'],
})

export class BundleGalleryCardComponent extends BaseComponent implements OnInit {


  ngOnInit() {
  }

  protected onSetData(data: any = this._data) {
    console.log('dqwdqwdqwd',data)
    super.onSetData(data);
  }


}
