<header>
  <div class="desktop">
    <div class="toolbar" (click)="toggleMobileMenu()">
      <div class="wrapper">
        <bundle class="reservation-place"></bundle>
        <!--<core-languages class="language_switch"></core-languages>-->
        <!--                <a href="tel:{{getLocale('top_info_contact_phone_number')}}">{{getLocale('top_info_contact_phone')}}{{getLocale('top_info_contact_phone_number')}}</a>-->
      </div>
    </div>
    <div class="clear"><!-- --></div>
    <div class="wrapper space-around nav">
      <a routerLink="{{'/'}}">{{'menu_reservation'|trans}}</a>
      <div class="logobar">
        <a routerLink="{{'/'|href}}" class="logo" *ngIf="owner?.logo">
          <app-core-media [url]="owner?.logo" [width]="300" [height]="100" [quality]="100" [method]="'resize'"></app-core-media>
<!--          <app-core-image *ngIf="owner?.logo && didIsImgFromString(owner?.logo)" [width]="145" [height]="81" [quality]="100" [method]="'resize'" [url]="owner?.logo"></app-core-image>-->
<!--          <app-core-svg *ngIf="owner?.logo  && didIsSvgFromString(owner?.logo)" [url]="owner?.logo"></app-core-svg>-->
        </a>
      </div>
      <a routerLink="{{'/O-nas'}}" (click)="toggleMobileMenu()">{{'menu_about_us'|trans}}</a>
    </div>
    <div class="clear"><!-- --></div>
  </div>

  <div class="mobiles">
        <div class="topbar">
            <div class="icon menuicon" (click)="toggleMobileMenu()">
                <app-core-svg [url]="'/assets/staticresource/layout/base/menu.svg'"></app-core-svg>
                <span></span>
                <span></span>
                <span></span>
            </div>
          <div class="logo_text">
<!--            <a routerLink="{{'/'|href}}" (click)="toggleMobileMenu()" class="logo" *ngIf="owner?.logo">-->
<!--              <app-core-image *ngIf="owner?.logo" [width]="260" [height]="80" [quality]="100" [method]="'resize'" [url]="owner?.logo"></app-core-image>-->
<!--            </a>-->
            <a routerLink="{{'/'|href}}" class="logo" *ngIf="owner?.logo">
              <app-core-media [width]="220" [height]="60" [quality]="100" [method]="'resize'"
                              [url]="owner?.logo"></app-core-media>
            </a>
            <div>{{'logo_text'|trans}}</div>
          </div>
            <div class="icon menuiconclose" (click)="toggleMobileMenu()">
                <app-core-svg [url]="'/assets/staticresource/layout/base/close.svg'"></app-core-svg>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="nav">
            <app-core-nav [forcedElementId]="1" [recursive]="true"  class="base" [menu]="menu"></app-core-nav>
        </div>
    </div>

</header>
