<div class="contact" *ngIf="selectedPlace">

  <div class="first-container">
    <h2>Kontakt</h2>

    <p>Wybierz lokalizację:</p>

    <form-custom-select *ngIf="placesToSelect.length > 0" [selected]="selectedPlace.id + ''" [options]="placesToSelect" [settings]="settings" (onChange)="selectPlace($event)" ></form-custom-select>
  </div>

  <div class="flexbox second-container">
    <div class="map-container" [ngClass]="{'hide': !isShowMapContainer}">
      <bundle class="map-map" [map]="map" (isShowMapEmitter)="showMapContainer($event)"></bundle>
    </div>
    <div class="address-container" [ngClass]="{'full': !isShowMapContainer}">
        <div>
          <div class="name" *ngIf="selectedPlace.name" [innerHTML]="selectedPlace.name|safeHtml"></div>
          <div class="address" *ngIf="selectedPlace.address" [innerHTML]="selectedPlace.address|safeHtml"></div>
          <div class="phone" *ngIf="selectedPlace.phone"><strong [innerHTML]="selectedPlace.phone|safeHtml"></strong></div>
          <div class="email" *ngIf="selectedPlace.email"><strong [innerHTML]="selectedPlace.email|safeHtml"></strong></div>
      </div>
    </div>
  </div>


  <div class="main-opinion-container" *ngIf="selectedPlace.placeHeader && selectedPlace.placeDescription">
    <div class="main-opinion">
      <app-core-image *ngIf="selectedPlace.minPhoto" [url]="selectedPlace.minPhoto" [width]="720" [height]="720" [quality]="90" [method]="'adjust'"></app-core-image>
      <div>
        <div class="rating">
          <app-core-rating [amountOfStars]="5" [currentValue]="selectedPlace.avgRating"></app-core-rating>
        </div>
      <div class="main-opinion-desc">
        <div class="header" *ngIf="selectedPlace.placeHeader" [innerHTML]="selectedPlace.placeHeader"></div>
        <div class="description" *ngIf="selectedPlace.placeDescription" [innerHTML]="selectedPlace.placeDescription"></div>
      </div>
      </div>
    </div>
  </div>

  <div class="opinions-container">
    <bundle class="opinion" [placeId]="selectedPlace.id"></bundle>

  </div>

  <div class="gallery-container" *ngIf="selectedPlace.gallery">
    <div class="gallery-title">Galeria zdjęć</div>
    <bundle class="gallery-slick" [data]="selectedPlace.gallery"></bundle>
  </div>

</div>
