import {AfterContentInit, Component, Inject, Injector, Input, OnInit} from '@angular/core';
import {BaseComponent} from "./base.component";
import {FormField, FormForm} from "../models/FormForm.model";
import {convertToHtmlLink} from "../utils/Forms";
import {FormFormService} from "../services/form.api.service";
import {AppConfig} from "../config/app.config";
import {ApiLink} from "../utils/utils";
import {CookieService} from "ngx-cookie-service";
import {CoreMessage} from "../services/CoreMessage";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-form-base',
  template: '',
})
export abstract class FormBaseComponent extends BaseComponent {

  protected formService;
  protected cookieService;
  protected apiUrl = AppConfig.endpoints.serverApi;

  protected _urlSend = ApiLink('/front-form')

  @Input()
  public message : CoreMessage;

  @Input()
  set urlSend(link) {
    this._urlSend = ApiLink(link)
  }

  get urlSend() {
    return this._urlSend
  }

  @Input()
  set formId(id) {
    if (id) {
      console.log('id', id)
      this.apiService.getByStaticUrl(ApiLink(`/cms/front_forms/${id}`)).subscribe(form => {
        // this.data = form;
        this.onSetData(form);
      })
    }
  }

  @Input() forcedData: any; // data['{{nazwa_szkolenia}}'] - {{nazwa_szkolenia}} jako wartośc domyslna przez formularz
  @Input() formForm: FormForm;
  @Input() submit // funkcja do obsługi submita.
  forValidationErrorList = [];


  constructor(protected _injector: Injector, protected route: ActivatedRoute) {
    super(_injector);
    this.cookieService = this._injector.get(CookieService);
    this.formService = this._injector.get(FormFormService);
  }

  checkboxDescription(formField: FormField) {
    return convertToHtmlLink(formField.textDescription);
  }

  public generateId(...args: string[]): string {
    let prefixes = [
      'generatedForm_', 'field_', '_'
    ];

    return args.map((arg, index) => {
      return ((prefixes[index] || "") + arg);
    }).join("_");
  }

  public getRef() {
    return this.cookieService.get('ref')
  }

  protected onSetData(data: any = this._data) {
    if (data.defaultUrl){
      this.urlSend = data.defaultUrl.replace(/\/api\/../g, '');
    }

    console.log('form', data);

    this.formForm = data;

    super.onSetData(data);
    this.setForcedData(this.formForm);
  }

  protected setForcedData(formForm) {
    if (this.forcedData) {
      formForm.sections.map(el => el.fields).reduce((prev, next) => [...prev, ...next])
        .forEach(field => {
          if (this.forcedData[field.textLabel]) {
            field.defaultValue = this.forcedData[field.textLabel]
          }
        })
    }
  }


}
