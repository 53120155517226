<div class="wrapper">
<div class="reservation_component">
    <ng-container *ngIf="component == 'confirm'">
        <bundle class="reservation-manage" [manageType]="component"></bundle>
    </ng-container>
    <ng-container *ngIf="component == 'decline'">
        <bundle class="reservation-manage" [manageType]="component"></bundle>
    </ng-container>
    <ng-container *ngIf="component == 'reservation'">
        <bundle class="reservation-progressbar" [step]="step" [stepCounter]="stepCounter"></bundle>
        <div class="step1" *ngIf="step == 1">
            <span class="header" [innerHtml]="'reservation_step_1_header'|trans"></span>

            <ng-container *ngIf="oneserviceListOptions && oneserviceListOptions.length > 0">
              <span class="description" [innerHtml]="'reservation_step_1_description_one_service'|trans|safeHtml"></span>
            </ng-container>

            <ng-container *ngIf="oneserviceListOptions === undefined || oneserviceListOptions.length <= 0">
              <span class="description" [innerHtml]="'reservation_step_1_description'|trans|safeHtml"></span>
            </ng-container>

            <div class="selectBox">
              <ng-container *ngIf="oneserviceListOptions && oneserviceListOptions.length > 0"></ng-container>

              <ng-container *ngIf="oneserviceListOptions === undefined || oneserviceListOptions.length <= 0">
                <form-custom-select *ngIf="categoryListOptions.length" [selected]="selectedCategory" [options]="categoryListOptions" [settings]="settings" (onChange)="setectCategory($event)" ></form-custom-select>
                <form-custom-select *ngIf="testifyListOptions.length" [selected]="selectedTestify" [options]="testifyListOptions" [settings]="settings" (onChange)="setectTestify($event)" ></form-custom-select>
              </ng-container>
<!--                <select class="bigBtn" [(ngModel)]="selectedCategory" name="selectedCategory" (change)="setectCategory()" *ngIf="categoryList?.length > 1">-->
<!--                    <option [ngValue]="-1">{{getLocale('reservation_step_1_select_category')}}</option>-->
<!--                    <option *ngFor="let category of categoryList" [ngValue]="category.id">{{category?.name}}</option>-->
<!--                </select>-->
<!--                <select class="bigBtn" [(ngModel)]="selectedTestify" name="selectedTestify" (change)="setectTestify()" *ngIf="testifyList?.length > 1">-->
<!--                    <option [ngValue]="-1">{{getLocale('reservation_step_1_select_testify')}}</option>-->
<!--                    <option *ngFor="let testify of testifyList" [ngValue]="testify?.id">{{testify?.name}} - {{testify?.reservationTestifyCategory?.name}}</option>-->
<!--                </select>-->
             </div>

        </div>

        <div class="step2" *ngIf="step == 2">
            <span class="header_small">
                {{'reservation_step_1_header'|trans}}
            </span>
            <div class="selectBox" *ngIf="testifyList?.length > 1 || categoryList?.length > 1 || serviceList?.length > 1">

              <span (click)="goToStep1()" class="normalBtn" style="width: 320px; max-width: 100%;">Wybierz inną usługę</span>
<!--                <div *ngIf="serviceList?.length > 1">-->
<!--                  <form-custom-select *ngIf="serviceList.length" [selected]="" [options]="oneserviceListOptions" [settings]="settings" (onChange)="setectTestify($event)" ></form-custom-select>-->
<!--                </div>-->

                <div *ngIf="categoryList?.length > 1 && serviceList?.length <= 0">
                    <label for="setectCategory">
                        {{'reservation_step_1_select_category'|trans}}
                    </label>
                    <select [(ngModel)]="selectedCategory" name="selectedCategory" (change)="setectCategory()" id="setectCategory" class="normalBtn">
                        <option *ngFor="let category of categoryList" [ngValue]="category.id">{{category?.name}}</option>
                    </select>
                </div>

                <div *ngIf="testifyList?.length > 1 && serviceList?.length <= 0">
                    <label for="setectTestify">
                        {{'reservation_step_1_select_testify'|trans}}
                    </label>
                    <select [(ngModel)]="selectedTestify" name="selectedTestify" (change)="setectTestify()" id="setectTestify" class="normalBtn">
                        <option *ngFor="let testify of testifyList" [ngValue]="testify?.id">{{testify?.name}} - {{testify?.reservationTestifyCategory?.name}}</option>
                    </select>
                </div>
            </div>
            <div class="dateSwitcher">
                <span class="nav prev" (click)="datePrev()"><</span>
                <span class="currentDate"><span class="from">{{dateFrom| date :  "dd.MM.y"}}</span><span class="separator">{{dateSeparator}}</span><span class="to">{{dateTo| date :  "dd.MM.y"}}</span></span>
                <span class="nav next" (click)="dateNext()">></span>
            </div>
            <div class="harmonogram">
                <div *ngFor="let testify of harmonogram" class="testify">
                    <div class="day">
                        <div class="header">
                            <span>&nbsp;<br>&nbsp;</span>
                        </div>
                        <div class="testify_desc">
                            <span>{{testify?.testify?.name}}</span>
                            <div [innerHtml]="testify?.testify?.description"></div>
                            <span>{{testify?.testifyCategory?.name}}</span>
                        </div>
                    </div>
                  <ng-container *ngFor="let dayOfWeek of testify.weekHarmonogram">
                    <div *ngIf="dayOfWeek?.harmonogram.length > 0" class="day">
                      <div class="header">
                        <span>{{dayOfWeek?.day?.name}}</span>
                        <span>{{dayOfWeek?.day_date| date :  "dd/MM/y"}}</span>
                      </div>
                      <div *ngIf="dayOfWeek?.harmonogram.length < 1 " class="items">
                        <span class="outOfTerms">{{'reservation_harmonogram_term_not_available'|trans}}</span>
                      </div>
                      <div *ngIf="dayOfWeek?.harmonogram.length > 0 " class="items">
                        <ng-container *ngFor="let oneRow of dayOfWeek?.harmonogram">
                          <span *ngIf="oneRow.status == 0" (click)="termForReservation(testify?.testify, oneRow?.start)">{{oneRow.startShort}} - {{oneRow.endShort}}</span>
                          <span *ngIf="oneRow.status != 0" class="unAvailable">{{oneRow.startShort}} - {{oneRow.endShort}}</span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <div class="day emptyDay" *ngIf="isEmptyWeek(testify.weekHarmonogram)">
                      <span class="outOfTerms">{{'reservation_harmonogram_term_not_available'|trans}}</span>
                  </div>

                    <!--<div *ngFor="let oneDay of dayList">{{// day}}</div>-->
                </div>
            </div>
        </div>
        <div class="step3" *ngIf="step == 3">
            <span class="header">
                {{'reservation_step_1_header'|trans}}
            </span>
            <div class="step3_content">
            <div class="termDescription">
                <span (click)="changeTerm()" class="normalBtn">{{'reservation_btn_change_term'|trans}}</span>
                <table>
                    <tr>
                        <th>{{'reservation_step_3_term_when'|trans}}</th>
                        <td>
<!--                            {{ getDateString(reservationAtForReservation)}}-->
                            {{ reservationAtForReservation|date:"dd/MM/yyyy" }}
                        </td>
                    </tr>
                    <tr>
                        <th>{{'reservation_step_3_term_when_time'|trans}}</th>
                        <td>
<!--                            {{ getTimeString(reservationAtForReservation)}}-->
                            {{ reservationAtForReservation|date:"HH:mm"}}
                        </td>
                    </tr>
                    <tr *ngIf="testifyForReservation?.place && (testifyForReservation?.place?.name || testifyForReservation?.place?.address)">
                        <th>{{'reservation_step_3_term_where'|trans}}</th>
                        <td><div [innerHtml]="testifyForReservation?.place?.name"></div>
                            <div [innerHtml]="testifyForReservation?.place?.address"></div>
                        </td>
                    </tr>
                    <tr *ngIf="oneSelectedService">
                      <th>{{'reservation_step_3_term_which_service'|trans}}</th>
                      <td>
                        {{oneSelectedService.name}}
                      </td>
                    </tr>
                    <tr *ngIf="testifyForReservation?.place && testifyForReservation?.place?.phone">
                        <th>{{'reservation_step_3_term_phone'|trans}}</th>
                        <td>
                            <span>{{testifyForReservation.place.phone}}</span>
                        </td>
                    </tr>
<!--                    <tr>-->
<!--                        <th>{{getLocale('reservation_step_3_term_testify')}}</th>-->
<!--                        <td>-->
<!--                            {{testifyForReservation.name}}-->
<!--                            <span *ngIf="testifyForReservation?.reservationTestifyCategory">{{testifyForReservation?.reservationTestifyCategory?.name}}</span>-->
<!--                        </td>-->
<!--                    </tr>-->
                </table>

            </div>

            <bundle class="reservation-form" [testify]="testifyForReservation" [reservationAt]="reservationAtForReservation" [oneService]="oneSelectedService" [formId]="1"></bundle>
            </div>
        </div>
        <div class="step4" *ngIf="step == 4">
            <span class="header">
                {{'reservation_step_4_header'|trans}}<ng-container *ngIf="isEmailConfirm">&nbsp;{{'reservation_step_4_email_confirm'|trans}}</ng-container>
            </span>
            <span class="description">
                {{'reservation_step_4_description'|trans}}
            </span>
        </div>
    </ng-container>
</div>
</div>

<div class="overlay_box" *ngIf="serviceListForShow && serviceListForShow.length > 0 && (step == 1)">
    <div class="wrapper">
<!--        <h3>-->
<!--            {{getLocale('service_prepayment_select_service_header')}}-->
<!--        </h3>-->
        <table>
            <thead>
            <tr>
                <th class="c1">{{'service_prepayment_header_name'|trans}}</th>
<!--                <th class="c2">{{getLocale('service_prepayment_header_duration')}}</th>-->
                <th class="c3">{{'service_prepayment_header_cost'|trans}}</th>
<!--                <th class="c4">{{getLocale('service_prepayment_header_prepayment')}}</th>-->
                <th class="c5"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let oneService of serviceListForShow ">
                <td class="c1"><strong [innerHtml]="oneService?.name"></strong>
                  <p [outerHTML]="oneService?.description" class="onDesc"></p>
                </td>
<!--                <td [innerHtml]="oneService?.duration"></td>-->
                <td><span *ngIf="oneService?.priceBrutto > 0" [innerHtml]="oneService?.priceBrutto + 'zł'"></span></td>
<!--                <td><span [innerHtml]="oneService?.priceBrutto"></span>-->
<!--                  <span class="not_required">{{getLocale('service_prepayment_not_required')}}</span>-->
<!--                </td>-->
                <td class="c5"><span (click)="selectService(oneService)" class="normalBtn">{{'service_btn_select'|trans}}</span></td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
