import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";



@Component({
  selector: 'bundle.slick',
  templateUrl: './bundle.slick.component.html',
  styleUrls: ['./bundle.slick.component.scss'],
})

export class BundleSlickComponent implements OnInit{

  public slideConfig = {
    'slidesToShow': 3,
    'slidesToScroll': 3,
    'fade': false,
    'autoplay': false,
    'dots': true,
    'arrows': false,
  };

  // responsive: [{
  //   breakpoint: 1200,
  //   settings: {
  //     "slidesToShow": 3,
  //     "fade": false,
  //     "slidesToScroll": 1,
  //     dots: true
  //   }
  // }, {
  //   breakpoint: 768,
  //   settings: {
  //     "slidesToShow": 1,
  //     "fade": false,
  //     "slidesToScroll": 1,
  //     dots: true
  //   }
  // }]

  public _data: any;

  public _componentSelektor = "";

  @Input()
  set config(config: any) {
    this.slideConfig = Object.assign({},this.slideConfig, config);
  }

  @Input()
  set componentSelektor(name: string) {
    if(name.includes('bundle.')) {
      name = name.replace("bundle.", "");
    }
    this._componentSelektor = name;
  }

  @Input()
  set data(data) {
    if (data) {
      this._data = data;
      this.onSetData(data);
    }
  }

  get data() {
    return this._data
  }

  protected onSetData(data: any = this._data) {

    console.log('onSetData slick', data);
    // console.log(thi)
  }


  ngOnInit(): void {}

}
