import {InjectionToken} from "@angular/core";
import {IVarConfig} from './ivar.config';

export let APP_CONFIG = new InjectionToken('app.variables');

export const AppVariables = {
    locale: {},
    Languages: null,
    pagination: {
      default: 9,
    }
};
