import { Pipe, PipeTransform } from '@angular/core';
import {AppVariables} from '../config/app.variables';
import {AppConfig} from '../config/app.config';

@Pipe({
  name: 'isUrlVisit',
  pure: false
})
export class IsUrlVisitPipe implements PipeTransform {

  transform(value: string, ...args: any[]): boolean {
    return value === window.location.pathname
  }
}
