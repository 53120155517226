import {Component, Injector, Input, OnInit} from '@angular/core';
import {CoreLanguageService} from '../../shared/bundles/core/language/language.service';
import {AppConfig} from '../../config/app.config';
import {BaseComponent} from '../../bundles/base.component';
import {AppVariables} from '../../config/app.variables';
import {CoreLanguage} from '../../shared/bundles/core/language/language.model';
import {Router} from '@angular/router';
import {CustomSelectOptionModel} from '../../bundles/form/customselect/customSelectOptionModel';


@Component({
  selector: 'core-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  providers: []
})
export class LanguagesComponent extends BaseComponent implements OnInit {

  constructor(protected _injector: Injector, private router: Router) {
    super(_injector);
  }

  langs: CustomSelectOptionModel[] = [];
  selectedLangId = null;

  ngOnInit() {
    this.urlService.url.subscribe(url => {
      let langFromUrl = this.urlService.getLang();
      if (langFromUrl.id != this.selectedLangId){
        this.selectedLangId = langFromUrl.id;
      }
      if (this.langs.length === 0) {
        this.langs = AppVariables.Languages.getForSelect()
      }
    })
  }

  changeLang(id) {
    if(id != this.selectedLangId){
      let shortName = AppVariables.Languages.getById(id).shortName
      AppConfig.language = shortName;
      if (!AppVariables.Languages.isDefault(shortName)) {
        this.router.navigate(['/' + shortName + this.urlService.getUrlWithoutLang()], {replaceUrl: true});
      }else {
        this.router.navigate([this.urlService.getUrlWithoutLang()], {replaceUrl: true})
      }
    }
  }

}
