<div class="flexbox">
  <ng-container *ngIf="_data && _data.length > 0">
    <div class="button-gray" [ngClass]="{'button-blue': !params?.cmsid, 'button-gray': params?.cmsid !== 0}"
         routerLink="{{urlService.getUrl()+',wszystkie,0'}}">Wszystkie</div>
    <!--         routerLink="{{urlService.getUrl() +',wszystkie,0'}}">Wszystkie</div>-->

    <ng-container *ngFor="let element of _data">
      <div [ngClass]="{'button-blue': element.url|isUrlVisit, 'button-gray': (element.url|isUrlVisit) == false}"
           routerLink="{{element.url}}">
        {{element.txt}}
      </div>
    </ng-container>
  </ng-container>
  <ng-content></ng-content>
</div>
