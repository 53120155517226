import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-core-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
    providers: []
})

export class MediaComponent {

  @Input() quality: number;
  @Input() method: string;
  @Input() width: number;
  @Input() height: number;
  @Input() alt: number;
  @Input() url: string;



}
