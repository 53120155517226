import {Component, Inject, Injector, Input, OnInit} from '@angular/core';
import {ConverterService} from '../services/converter.service';
import {ApiService} from '../services/api.service';
import {UrlService} from '../services/urlService';

@Component({
  selector: 'app-base-component',
  template: '',
})
export abstract class BaseComponent {
    protected apiService: ApiService;
    public urlService: UrlService;
    protected converter: ConverterService;

    protected _slot;

    public _data;

    constructor(
      protected _injector: Injector
    ){
      this.apiService = this._injector.get(ApiService);
      this.urlService = this._injector.get(UrlService);
      this.converter = this._injector.get(ConverterService);
    }

    protected convert(data, entity = this._slot?.dataEntity || null, plugKey = this._slot?.plugtemplate.plugKey || null) {
      if (!entity && !plugKey){
        return data;
      }
      return this.converter.convert(data, entity, plugKey);
    }

    protected onSetData(data = this._data) {
      // console.log('onSetData', data);
    }

    @Input()
    set slot(slot) {
      this._slot = slot;
      // console.log('_slot', slot);
      let url = this._slot.dataUrl || this._slot.dataGetterUrl;
      // console.log('url',url)
      if (url) {
        this.apiService.getByStaticUrl(url).subscribe(data => {
          let dataConvert = this.convert(data, this._slot.dataEntity, this._slot.plugtemplate.plugKey);
          // console.log('----------------------------')
          // console.log(this._slot.dataEntity + ", " + this._slot.plugtemplate.plugKey+": ", dataConvert, data)
          // console.log('----------------------------')

          if (dataConvert) {
            this._data = dataConvert;
          }else {
            console.error('Wystąpił nieoczekiwany błąd w convert\'terze.')
          }
          this.onSetData(this._data);
        })
      }
    };

    @Input()
    set data(data) {
      this._data = data;
      this.onSetData(data);
    }

    get data(){
      return this._data
    }

}
