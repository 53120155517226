import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {BaseComponent} from '../../base.component';
import News from "../news-list-slick/bundle.news-list-slick.component.interface";

@Component({
  selector: 'bundle.news-card',
  templateUrl: './bundle.news-card.component.html',
  styleUrls: ['./bundle.news-card.component.scss'],
  providers: []
})

export class BundleNewsCardComponent extends BaseComponent implements OnInit{

  public _data: News;

  ngOnInit(): void {}

}
