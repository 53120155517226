import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {CoreMessage} from "../../../services/CoreMessage";
import {AppReservationService} from "../../../shared/bundles/app/reservation/reservation.service";
import {ActivatedRoute} from "@angular/router";
// import {BaseComponent} from '../../../core/base.component';
// import {AppService} from '../../../core/services/app.service';
// import {CoreMessage} from '../../../shared/bundles/core/message/message.model';
// import {ActivatedRoute} from '@angular/router';
// import {StructurePageService} from '../../../shared/bundles/structure/page/page.service';
// import {ReservationReservationService} from '../../../shared/bundles/reservation/reservation/reservation.service';

@Component({
    selector: 'bundle.reservation-manage',
    templateUrl: './bundle.reservation.manage.component.html',
})

export class BundleReservationManageComponent extends BaseComponent implements OnInit{

    @Input() manageType: string;
    message: CoreMessage;

    constructor(protected _injector: Injector,private reservationReservationService: AppReservationService,  private route: ActivatedRoute) {
        super(_injector);
    }

    ngOnInit() {

        let params: any = this.route.snapshot.queryParams;
        let postData = {};
        if (params.email) {
            postData['email'] = params.email;
        }
        if (params.hash) {
            postData['hash'] = params.hash;
        }

        var id = null;
        if (params.elid) {
            id = params.elid;
        }
        postData['manageType'] = this.manageType;

        this.message = new CoreMessage('loader');

        // this.reservationReservationService.update(id, postData).subscribe(
        //     data => {
        //         this.message = new CoreMessage(data['type'], data['header'], data['content']);
        //     },
        //     () => {
        //     }
        // );
    }
}
