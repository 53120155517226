import {Component, Input} from '@angular/core';

import {CoreMessage} from '../../../services/CoreMessage';
import {FormFormService} from '../../../services/form.api.service';


@Component({
  selector: 'bundle.newsletter.footer',
  templateUrl: './bundle.newsletter.footer.component.html',
  styleUrls: ['./bundle.newsletter.footer.component.scss'],
  providers: []
})
export class BundleNewsletterFooterComponent {

    message : any;
    forValidationErrorList = [];

    constructor(private formService: FormFormService) {}

    submitPost(data) {
        let formKey : string = 'NewsletterFormSubscribe';
        this.formService.clearFormMessage(formKey);
        let postData = this.formService.retriveData(formKey, data);

        this.formService.send(postData).subscribe(
            data => {
                let result = this.formService.retriveResponse(formKey, data, false, true);
                if(result instanceof CoreMessage){
                    this.message = result;
                }else{
                    this.forValidationErrorList = result;
                }
            },
            () => {
            }
        );
    }

}
