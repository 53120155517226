import {EventEmitter, Inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHandler} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import {AppConfig} from '../config/app.config';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppService} from '../core/services/app.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected request$: EventEmitter<any>;
  protected apiUrl: string;
  getAllCache: Observable<any>;

  public http: HttpClient;
  public appService: AppService;

  constructor(
    protected _injector: Injector,
  ) {
    this.http = this._injector.get(HttpClient)
    this.appService = this._injector.get(AppService);
    this.apiUrl = AppConfig.endpoints.serverApi;
    this.request$ = new EventEmitter();
  }

  protected handleError(error: any): Observable<any> {
    this.request$.emit('finished');
    if (error instanceof Response) {
      return throwError(error.json()['error'] || 'backend server error');
    }
    return throwError(error || 'backend server error');
  }

  public getByStaticUrl(url) {
    return this.http.get(url, this.appService.getOptions()).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  public post(url, data) {
    return this.http.post(url,data, this.appService.getOptions()).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  public getPlainText(url) {
    return this.http.get(url, { responseType: 'text' });
  }

}
