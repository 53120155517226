
<ng-container *ngIf="data && data.length > 0">
  <ngx-slick-carousel *ngIf="slideConfig " class="carousel" #slickModal='slick-carousel' [config]="slideConfig" >
    <ng-container *ngFor="let element of data; let i = index">
      <div ngxSlickItem class="slide">
        <app-dynamic-selector [inputs]="{data: element, sortPosition: i}" [componentSelector]="'bundle.' + _componentSelektor"></app-dynamic-selector>
      </div>
    </ng-container>
  </ngx-slick-carousel>
</ng-container>
