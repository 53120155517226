import {Component, Injector, OnInit} from '@angular/core';
import {AppConfig} from '../../config/app.config';
import {CMSPageService} from '../../shared/bundles/cms/page/page.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../bundles/base.component';
import {AppVariables} from '../../config/app.variables';

@Component({
    selector: 'app-core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: []
})

export class HeaderComponent extends BaseComponent implements OnInit{

    socialList = [];

    menu = [];
    languagesOptions = [];

    constructor(protected _injector: Injector,
                private router: Router,
    ) {
        super(_injector);

        this.mobileMenuCloseTrigger();


        this.socialList.push({
            type: 'pinterest',
            active: true,
            url: 'https://pinterest.pl'
        });
        this.socialList.push({
            type: 'facebook',
            active: true,
            url: 'https://pinterest.pl'
        });
        this.socialList.push({
            type: 'pinterest',
            active: true,
            url: 'https://pinterest.pl'
        });
        this.socialList.push({
            type: 'pinterest',
            active: true,
            url: 'https://pinterest.pl'
        });

    }

    ngOnInit(): void {
      // AppVariables.Languages.getForSelect()
      this.apiService.getByStaticUrl(AppConfig.endpoints.serverApi + '/pl/cms/menu_elements.json?parent=1').subscribe((menu: any) => {
        this.menu = menu.sort((a, b) => {
          return a.sortPosition - b.sortPosition;
        });

        console.log('menu', menu)
      });
    }

    get owner() {
        return AppConfig.owner;
    }

    mobileMenuCloseTrigger(): void {

    }

    closeMobileMenu(): void {
        var list = document.getElementsByTagName('header');
        if (list[0]) {
            list[0].classList.remove('opened');
        }
    }

    toggleMobileMenu(): void {
        var list = document.getElementsByTagName('header');
        if (list[0]) {
            if (list[0].classList.contains('opened')) {
                list[0].classList.remove('opened');
            } else {
                list[0].classList.add('opened');
            }
        }
        this.showMobileModules();
    }

    showMobileModules(moduleName = ''): void{
        var mobileModuleListHtmlColl = document.getElementsByClassName('header-mobile-module');
        if (mobileModuleListHtmlColl && mobileModuleListHtmlColl.length > 0){
            var mobileModuleList = Array.from(mobileModuleListHtmlColl);
            var index;
            var className = 'header-mobile-module-' + moduleName.toLowerCase();
            for (index = 0; index < mobileModuleList.length; ++index) {
                var el = (mobileModuleList[index] as HTMLElement);
                if(el.classList.contains(className) && el.style.display !== 'flex') {
                    el.style.display = 'flex';
                }else{
                    el.style.display = 'none';
                }
            }
        }
    }


}
