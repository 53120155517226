import {Component, Injector, OnInit} from '@angular/core';

import {CustomSelectSettingsModel} from '../../form/customselect/customSelectSettingsModel';
import {CustomSelectOptionModel} from '../../form/customselect/customSelectOptionModel';
import {BaseComponent} from "../../base.component";
import {AppPlaceService} from "../../../shared/bundles/app/place/place.service";

@Component({
  selector: 'bundle.contact',
  templateUrl: './bundle.box.contact.component.html',
  styleUrls: ['./bundle.box.contact.component.scss'],
})

export class BundleBoxContactComponent extends BaseComponent implements OnInit {
    // @Input() slot;
    // box: BoxBox;

    settings = new CustomSelectSettingsModel(true, false);
    placesToSelect = [];
    selectedPlace = null;
    selectedSelect = null;
    places = [];
    map = {};

    isShowMapContainer = true;

    constructor(protected _injector: Injector, private placesService: AppPlaceService) {
        super(_injector);
    }

    ngOnInit(): void {
      this.placesService.getAll().subscribe(places => {

        console.log('places', places);
        this.places = places;
        this.placesToSelect = places.map(place => new CustomSelectOptionModel(place.id + '', place.name));
        this.selectedPlace = places[0];
        this.selectedSelect = new CustomSelectOptionModel(places[0].id + '', places[0].name)
        this.setMapObj(this.selectedPlace)
      });
    }

    public selectPlace(event): void {
      this.selectedPlace = this.places.find(el => el.id == event);
      this.selectedSelect = new CustomSelectOptionModel(this.selectedPlace.id + '', this.selectedPlace.name)
      this.setMapObj(this.selectedPlace)
    }

    setMapObj(selectedPlace){
      if (selectedPlace.mapLat && selectedPlace.mapLng) {
        if (selectedPlace.name) {
          var marker = {
            name: selectedPlace.name,
            description: selectedPlace.address || "",
            lat: selectedPlace.mapLat,
            lng: selectedPlace.mapLng
          }
        }
        this.map = {lat: selectedPlace.mapLat, lng: selectedPlace.mapLng, zoom: selectedPlace.mapZoom || 15, mapMapMarkers: [marker]}
      }else {
        this.map = {};
      }
    }

    showMapContainer($event) {
        this.isShowMapContainer = $event
    }

}
