import {Component, Injector, Input, OnInit} from '@angular/core';

import {BundleReservationReservationComponent} from '../reservation/bundle.reservation.reservation.component';
import {BaseComponent} from "../../base.component";
import {CoreMessage} from "../../../services/CoreMessage";
import {AppService} from "../../../core/services/app.service";
import {FormFormService} from "../../../services/form.api.service";
import {convertToHtmlLink} from "../../../utils/Forms";
import {FormField, FormForm} from "../../../models/FormForm.model";
import {FormBaseComponent} from "../../form.base.component";
import {BundleFormFormComponent} from "../../form/form/bundle.form.form.component";

@Component({
  selector: 'bundle.reservation-form',
  templateUrl: './../../form/form/bundle.form.form.component.html',
  styleUrls: ['./../../form/form/bundle.form.form.component.scss'],
})

export class BundleReservationFormComponent extends BundleFormFormComponent implements OnInit{

    @Input() reservationAt: string;
    @Input() testify: any;
    @Input() oneService: any;


    reservationComponent

    ngOnInit() {
       this.reservationComponent = this._injector.get(BundleReservationReservationComponent);
    }

    prepareData(formFd): any {
      formFd.append('reservationAt', this.reservationAt)
      formFd.append('testify', this.testify.id)
      formFd.append('oneService', this.oneService.id)
      return super.prepareData(formFd);
    }

    formAfterSend(response) {
      let result = super.formAfterSend(response);
      if(result.type == 'success') {
        this.reservationComponent.step = 4;
      }
    }

  // submitPost(formKey, data)
    // {
    //     this.formService.clearFormMessage(formKey);
    //     let postData: any;
    //     postData = this.formService.retriveData(formKey, data);
    //     postData.data.reservationAt = this.reservationAt;
    //     postData.data.testify = this.testify.id;
    //     postData.data.oneService = this.oneService.id;
    //     this.formService.send(postData).subscribe(
    //             data => {
    //             let result = this.formService.retriveResponse(formKey, data, true, true);
    //             if(result instanceof CoreMessage){
    //                 if(result.type == 'success'){
    //                     this.reservationComponent.step = 4;
    //                 }else{
    //                     this.message = result;
    //                 }
    //             }else{
    //                 this.forValidationErrorList = result;
    //             }
    //         },
    //         () => {
    //         }
    //     );
    // }
    //
    // checkboxLabel(formField: FormField) {
    //   var label: string = convertToHtmlLink(formField.label);
    //   return label;
    // }

}
