<div>
  <div class="loaderComponent">
    <div class="logo"></div>
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

</div>
