import {AppVariables} from '../config/app.variables';
import {AppConfig} from "../config/app.config";

export var getFileExtensionFromString = function(url: string): string {
  return url.split('.').pop();
};

export var didIsImgFromString = function(url: string): boolean {
  var extension = getFileExtensionFromString(url).toLowerCase();
  return (extension === 'jpg' || extension === 'jpeg' ||  extension === 'png');
};

export var didIsSvg = function (url:string): boolean {
  var extension = getFileExtensionFromString(url).toLowerCase();
  return extension === 'svg';
}

export var didIsMovieFromString = function(url: string): boolean {
  var extension = getFileExtensionFromString(url).toLowerCase();
  return (extension === 'mp4');
};

export var getLocale = function(key: string, debug: boolean = false, showKey: boolean = true, locale: any = ''): string {
  var retval;
  if (debug === true) {
    console.log(AppVariables.locale);
  }

  if (AppVariables.locale[key]) {
    var string = window.location.hostname;
    var substring = 'debug';
    if (string.indexOf(substring) == -1) {
      retval = AppVariables.locale[key].toString();
    } else {
      if (key.indexOf('page_id') == -1) {
        retval = AppVariables.locale[key] + ' ' + key;
      } else {
        retval = AppVariables.locale[key].toString();
      }

    }
  }

  return retval ? retval : showKey ? key : '';
};

export var ApiLink = function (link:string, removeApiPrefix = false){
  let linkstring = link.split("/").map(el => el.trim()).filter(el => el.length > 0).join("/")

  if (removeApiPrefix){
    return `${AppConfig.endpoints.serverApi.replace('/api', '')}/${linkstring}`
  }
  return `${AppConfig.endpoints.serverApi}/${AppConfig.language}/${linkstring}`
}

export var link = function (value: string, slug: string = "", cmsid: string = ''): string {
  let url = AppConfig.structurePageList.find(el => el.id == value)?.url;
  // console.log(value, url, AppConfig.structurePageList)
  let linkSlug = (slug ? slug + "," : "") + (cmsid && slug ? cmsid : "");
  let retval = `${url}${linkSlug ? "," + linkSlug : ""}`;
  return retval.trim();
}

export var buildFormData = function(data, formData = new FormData(), parentKey = null) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}
