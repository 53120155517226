import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CMSPageService} from '../../shared/bundles/cms/page/page.service';
// import {Breadcrumb} from '../../shared/bundles/core/breadcrumb/breadcrumb.model';
import {ActivatedRoute, Params} from '@angular/router';
// import {BreadcrumbService} from '../../shared/bundles/core/breadcrumb/breadcrumb.service';
import {Subscription} from 'rxjs/Subscription';
import {BaseComponent} from '../../bundles/base.component';

@Component({
    selector: 'bundle.core-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    providers: []
})

export class BreadcrumbsComponent extends BaseComponent implements OnInit, OnDestroy {

    public breadCrumbItemList: Array<any>;
    public pageTitle: string;
    public lastItem: any;
    public lastItemNumber: any;
    paramsSubscription: Subscription;

    constructor(protected _injector: Injector,
                private route: ActivatedRoute,
                // private breadcrumbService: BreadcrumbService
    ) {
        super(_injector);

    }


    ngOnInit() {
        this.breadCrumbItemList = [];
        this.lastItemNumber = 1;

        // this.urlService.url.subscribe()

        // this.apiService.getByStaticUrl()
        // this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
        //     var urlData = {};
        //     this.route.params.subscribe(
        //             (params: Params) => {
        //                 var url = '';
        //                 var i;
        //                 for (i = 0; i < 10; i++) {
        //                     var key = '';
        //                     if(i > 0)
        //                     {
        //                         key = i.toString();
        //                     }
        //                     var path = this.route.snapshot.params['page' + key];
        //                     if(path)
        //                     {
        //                         if(url)
        //                         {
        //                             url += '/';
        //                         }
        //                         url += path;
        //                     }
        //                 }
        //                 if(url)
        //                 {
        //                     urlData['params'] = url;
        //                     urlData['queryParams'] = queryParams;
        //                     // this.breadcrumbService.get(urlData).subscribe((list: Array<Breadcrumb>) => {
        //                     //     this.breadCrumbItemList = list;
        //                     //     var length = this.breadCrumbItemList.length;
        //                     //     this.lastItemNumber = length;
        //                     //     if (length > 0)
        //                     //     {
        //                     //         this.lastItem = '';
        //                     //         this.pageTitle = '';
        //                     //
        //                     //         var lastBreadcrumbListItem = this.breadCrumbItemList[this.breadCrumbItemList.length - 1];
        //                     //         if (lastBreadcrumbListItem.isPageTitle) {
        //                     //             this.pageTitle = lastBreadcrumbListItem.label;
        //                     //         }
        //                     //         this.lastItem = lastBreadcrumbListItem;
        //                     //
        //                     //         this.breadCrumbItemList.splice(-1, 1)
        //                     //     }
        //                     // });
        //                 }
        //             }
        //         );
        // });
    }

    ngOnDestroy() {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }

}
