<!--<span class="current" *ngIf="currentLanguage">{{ currentLanguage }}</span>-->
<!--<div class="language_switch">-->
<!--  <ng-container *ngFor="let language of languageList">-->
<!--    <a href="{{language.url}}" *ngIf="language.url">{{language.shortName}}</a>-->
<!--    <a href="/{{language.shortName}}" *ngIf="!language.url">{{language.shortName}}</a>-->
<!--  </ng-container>-->
<!--</div>-->
<ng-container *ngIf="langs.length > 0">
  <form-custom-select [options]="langs" [selected]="selectedLangId" (onChange)="changeLang($event)"></form-custom-select>
</ng-container>
