export class Filter {
  filter : any;

  constructor() {
    this.filter = {};
  }


  public addWhere(field, comparison, value)
  {
    if(!("w" in this.filter)){
      this.filter.w = [];
    }
    var w = [];
    w.push(field);
    w.push(comparison);
    w.push(value);
    this.filter.w.push(w);
  }

  public addWhereMany(field, comparison, value, relatedField, recursive = false)
  {
    if(!("wm" in this.filter)){
      this.filter.wm = [];
    }
    var wm = [];
    wm.push(field);
    wm.push(comparison);
    wm.push(value);
    wm.push(relatedField);
    wm.push(recursive);
    this.filter.wm.push(wm);
  }

  public addWhereTranslate(field, comparison, value)
  {
    if(!("wt" in this.filter)){
      this.filter.wt = [];
    }
    var wt = [];
    wt.push(field);
    wt.push(comparison);
    wt.push(value);
    this.filter.wt.push(wt);
  }

  public addOrder(field, direction)
  {
    if(!("o" in this.filter)){
      this.filter.o = [];
    }
    var o = {};
    o[field] = direction;
    this.filter.o.push(o);
  }

  public addOrderNatural(field, direction)
  {
    if(!("on" in this.filter)){
      this.filter.on = [];
    }
    var on = {};
    on[field] = direction;
    this.filter.on.push(on);
  }

  public addOrderTranslate(field, direction)
  {
    if(!("ot" in this.filter)){
      this.filter.ot = [];
    }
    var ot = {};
    ot[field] = direction;
    this.filter.ot.push(ot);
  }

  public limit(limit)
  {
    this.filter.l = limit;
  }

  public page(page)
  {
    this.filter.p = page;
  }

  public get()
  {
    return this.filter;
  }

}
