<!--<div class="wrapper" [ngClass]="{'spacebetween': showRealization === true}" *ngIf="('footer_realization'|trans) || ('footer_copyright'|trans)">-->
<!--    <span>© {{'footer_copyright'|trans}} {{ year }} {{owner?.name}}</span>-->
<!--    <span *ngIf="showRealization === true">{{'footer_realization'|trans}}-->
<!--        <ng-container *ngFor="let item of realization">-->
<!--            <a *ngIf="item?.url && item?.url.length>0" href="{{ item?.url }}" target="_blank">{{ item?.label }}</a>-->
<!--            <span *ngIf="!item?.url || item?.url.length<=0">{{ item?.label }}</span>-->
<!--        </ng-container>-->
<!--    </span>-->
<!--</div>-->
<!--<bundle class="core-cookiepopup"></bundle>-->

<div class="footer_menu">
  <div class="wrapper flexbox">
    <a href="{{'page_id_regulations'|trans|link}}">{{'footer_menu_regulations'|trans}}</a>
    <a href="{{'page_id_polityka_prywatnosci'|trans|link}}">{{'footer_menu_privacy_policy'|trans}}</a>
  </div>
</div>
<bundle class="core-cookiepopup"></bundle>
