// @todo RemoveThisMessage. If the message remains, the file could be regenerated!
import {Component, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AppOpinion} from './opinion.model';
import {Observable} from 'rxjs/Observable';

import {AppOpinionBaseService} from './opinion.base.service';
import {AppService} from '../../../../core/services/app.service';


@Injectable({
    providedIn: 'root'
})
export class AppOpinionService extends AppOpinionBaseService {

    constructor(protected http: HttpClient, protected appService: AppService) {
       super(http, appService);
    }

}
