import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from '../../base.component';
import {ActivatedRoute} from "@angular/router";
import {ApiLink} from "../../../utils/utils";
import {CoreMessage} from "../../../services/CoreMessage";

@Component({
  selector: 'bundle.confirmation',
  templateUrl: './bundle.confirmation.component.html',
  styleUrls: ['./bundle.confirmation.component.scss'],
  providers: []
})

export class BundleConfirmationComponent extends BaseComponent implements OnInit{

  protected route
  message

  ngOnInit() {
    this.route = this._injector.get(ActivatedRoute);
    this.submit();
  }

  submit() {
    let fd = new FormData();
    let hash = this.route.snapshot.queryParams['hash']
    fd.append('hash', hash);

    this.apiService.post(ApiLink('/confirm-reservation'), fd ).subscribe(response => {
      if (response instanceof CoreMessage) {
        this.message = response;
      }
    })

  }

}
