import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

// import {CookieService} from 'ngx-cookie-service';
// import {HttpModule} from '@angular/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {APP_CONFIG, AppConfig} from './config/app.config';
import {AppComponent} from './app.component';
// import {CoreModule} from './core/core.module';

import {HttpLoaderFactory} from './app.translate.factory';
import {BundlesModule} from './bundles/bundles.module';
import {RouterModule, Routes} from '@angular/router';
import {BreadcrumbsComponent} from './core/breadcrumbs/breadcrumbs.component';
import {ContentsectionComponent} from './core/contentsection/contentsection.component';
import {AppService} from './core/services/app.service';
import {CoreLocaleService} from './shared/bundles/core/locale/locale.service';
import {CoreLanguageService} from './shared/bundles/core/language/language.service';

import {LanguagesComponent} from './core/locale/locale.component';
// import {CoreCmsElementService} from './shared/bundles/core/cmselement/cmselement.service';
import {CoreDomainService} from './shared/bundles/core/domain/domain.service';
// import {CoreStructureService} from './shared/bundles/core/structure/structure.service';
// import {CoreAnimationService} from './shared/bundles/core/animation/animation.service';
// import {BreadcrumbService} from './shared/bundles/core/breadcrumb/breadcrumb.service';
//
//
// import {StructurePageService} from './shared/bundles/structure/page/page.service';
// import {StructureSlotService} from './shared/bundles/structure/slot/slot.service';
// import {StructureBlockService} from './shared/bundles/structure/block/block.service';
// import {StructureBlockoptionService} from './shared/bundles/structure/blockoption/blockoption.service';
// import {StructureSectionoptionService} from './shared/bundles/structure/sectionoption/sectionoption.service';
// import {StructureSlotoptionService} from './shared/bundles/structure/slotoption/slotoption.service';
// import {StructureSectionService} from './shared/bundles/structure/section/section.service';

import {AppRoutingModule} from './app-routing.module';
import {CommonModule} from '@angular/common';
// import {StructureNavService} from './shared/bundles/structure/nav/nav.service';
// import {PipesModule} from './pipes/pipes.module';
import {DynamicSelectorComponent} from './services/dynamic.selector.component';


@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    // HttpModule,
    // CoreModule,
    // PipesModule,
    BundlesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    AppComponent,
  ],

  declarations: [
    AppComponent,
  ],
  providers: [
    {provide: APP_CONFIG, useValue: AppConfig},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
