<div class="box" *ngIf="opinions">
  <div class="box-title">{{'opinions_header'|trans}}</div>

  <div class="opinions-container">
    <ng-container *ngIf="opinions.length === 0;else list">
      {{'opinions_list_empty'|trans}}
    </ng-container>
    <ng-template #list>
      <ng-container *ngFor="let opinion of opinions">
        <div class="opinion-container">
          <div class="opinion-content">
            <div>
              <div class="sign">{{opinion.person}}</div>
              <div class="description article-content"  [innerHTML]="opinion.content"></div>
            </div>
            <app-core-rating [amountOfStars]="5" [currentValue]="opinion.rate"></app-core-rating>
          </div>
        </div>
      </ng-container>
    </ng-template>

  </div>
</div>
