import {Component, Input, OnInit} from '@angular/core';

import {AppConfig} from '../../config/app.config';
import {CoreImageService} from '../../services/image.api.service';
import {ApiLink} from "../../utils/utils";

@Component({
  selector: 'app-core-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: []
})

export class ImageComponent implements OnInit {

  // @Input() url: string;
  @Input() quality: number;
  @Input() method: string;
  @Input() width: number;
  @Input() height: number;
  @Input() alt: number;
  @Input() showLoader = false;
  @Input() lazyLoading;
  @Input() bw = false;

  message: any;
  src: string;
  srcLazy: string;
  srcset: string;
  sizes: string;
  imgUrl:string

  constructor(private imageService: CoreImageService) {}

  @Input()
  set url(url){
    this.imgUrl = url
    this.ngOnInit();
  }

  ngOnInit() {
    this.sizes = '';
    this.srcset = '';
    this.src = '';
    if (!this.lazyLoading) {
      this.lazyLoading = AppConfig.photo.lazyLoading;
    }

    if (this.lazyLoading) {
      this.srcLazy = this.generateUrl(this.width, false, 10);
    }

    // if (this.showLoader) {
    //     this.message = new CoreMessage('loader', null, null, true);
    // }
    this.src = this.generateUrl(this.width, false, null);
    // this.src = this.imgUrl;

    var tthis = this;
    AppConfig.mobile.sizes.forEach(function (el) {
      if (tthis.width >= el) {
        if (tthis.sizes) {
          tthis.sizes += ', ';
          tthis.srcset += ', ';
        }
        tthis.srcset += tthis.generateUrl(el, true, null) + ' ' + el + 'w';
        tthis.sizes += '(max-width: ' + el + 'px) ' + el + 'px';
      }
    });

  }

  generateUrl(width, srcset, forcedQuality) {

    var url = this.imgUrl;
    if (!url){
      return null;
    }
    if (url.includes('https://') || url.includes('http://')){
      return url;
    }

    var data: any = {};
    data.quality = this.quality;
    if (forcedQuality >= 1) {
      data.quality = forcedQuality;
    }

    if (data.quality > 100) {
      data.quality = 100;
    }

    if (data.quality < 1) {
      data.quality = 1;
    }

    data.method = this.method;
    data.width = this.width;
    data.height = this.height;
    data.bw = this.bw;

    if (srcset) {
      if (AppConfig.photo.quality[width]) {
        if (AppConfig.photo.quality[width] < this.quality) {
          data.quality = AppConfig.photo.quality[width];
        }
      }

      if (this.width) {
        data.width = width;
      }

      if (this.width > 0 && this.height > 0) {
        var proportion = width / this.width;
        data.height = this.height * proportion;
        data.height = parseFloat(data.height).toFixed(2);
      }
    }


    var urlParams = '';
    if (data.height) {
      if (urlParams !== '') {
        urlParams += '&';
      }
      urlParams += 'h=' + data.height;
    }
    if (data.width) {
      if (urlParams !== '') {
        urlParams += '&';
      }
      urlParams += 'w=' + data.width;
    }
    if (data.quality) {
      if (urlParams !== '') {
        urlParams += '&';
      }
      urlParams += 'q=' + data.quality;
    }
    if (data.method) {
      if (urlParams !== '') {
        urlParams += '&';
      }
      urlParams += 'm=' + data.method;
    }
    if (data.bw) {
      if (urlParams !== '') {
        urlParams += '&';
      }
      urlParams += 'bw=' + 1;
    }

    if (urlParams !== '') {
      urlParams = '&' + urlParams;
    }

    return ApiLink(`/img?url=${encodeURI(url)}${urlParams}`)



    // files/public/logo/logo.png
  }

  bindLazy(el) {
    // document.addEventListener("DOMContentLoaded", function() {
    //     var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    //     console.log(lazyImages);
    // //     if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
    var entries = [];
    entries.push(el);
    // let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
    //     entries.forEach(function(entry) {
    //         if (<IntersectionObserverEntry>(entry).isIntersecting) {
    //             let lazyImage = entry.target;
    //             lazyImage.src = lazyImage.dataset.src;
    //             lazyImage.srcset = lazyImage.dataset.srcset;
    //             lazyImage.classList.remove("lazy");
    //             lazyImageObserver.unobserve(lazyImage);
    //         }
    //     });
    // });
    // //         lazyImages.forEach(function(lazyImage) {
    // //             lazyImageObserver.observe(lazyImage);
    //     });
  }

}
