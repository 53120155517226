import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import Box from './bundle.box.component.interface';
import {CMSBoxService} from '../../../shared/bundles/cms/box/box.service';
import {BaseComponent} from '../../base.component';


@Component({
  selector: 'bundle.box',
  templateUrl: './bundle.box.component.html',
  styleUrls: ['./bundle.box.component.scss'],
  providers: []
})

export class BundleBoxComponent extends BaseComponent{

  public _data: Box

  protected boxService

  constructor(protected _injector: Injector) {
    super(_injector);
    this.boxService = _injector.get(CMSBoxService);
  }

  @Input()
  set boxId(boxId){
    if (!isNaN(parseInt(boxId))) {
      this.boxService.getOne(boxId).subscribe(data => {
        this._data = this.convert(data);
      })
    }
  }

}
