import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
})
/**
 * Ochrona dla danych które są w formiacie HTML.
 */
export class SafeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(html): any  {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

}
