import {Component, Input} from '@angular/core';
import {CoreMessage} from "../../services/CoreMessage";


@Component({
  selector: 'app-core-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})

export class MessageComponent{
    @Input() message: CoreMessage;
}
