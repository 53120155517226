import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContentsectionComponent} from './core/contentsection/contentsection.component';
import {EntityConvertersMapResolver} from './resolvers/entity.converters.map.resolver';
import {AppComponent} from './app.component';
import {LanguagesResolver} from './resolvers/languages.resolver';
import {StructurePagesResolver} from "./resolvers/structure.pages.resolver";

const appRoutes: Routes = [
  // {
  //   path: ':language', component: ContentsectionComponent, children: [
  //     {path: ':page', redirectTo: '404'}
  //   ]
  // },
  //
  // {path: ':language/:page/:page1/:page2/:page3/:page4/:page5/:page6/:page7', component: ContentsectionComponent},
  // {path: ':language/:page/:page1/:page2/:page3/:page4/:page5/:page6', component: ContentsectionComponent},
  // {path: ':language/:page/:page1/:page2/:page3/:page4/:page5', component: ContentsectionComponent},
  // {path: ':language/:page/:page1/:page2/:page3/:page4', component: ContentsectionComponent},
  // {path: ':language/:page/:page1/:page2/:page3', component: ContentsectionComponent},
  // {path: ':language/:page/:page1/:page2', component: ContentsectionComponent},
  // {path: ':language/:page/:page1', component: ContentsectionComponent},
  // {path: ':language/:page', component: ContentsectionComponent},
  {
    path: '**', component: ContentsectionComponent, resolve: {
      converters: EntityConvertersMapResolver,
      languages: LanguagesResolver,
      pages: StructurePagesResolver,
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
