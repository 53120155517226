import {Injectable,} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ConverterService {

  protected _converterMap = {};

  constructor() {}

  set converterMap(json: any){
    console.log('setConverterMap', json);
    this._converterMap = json;
  }

  get converterMap() {
    return this._converterMap;
  }

  public convert(dataJson:any, entityName: string, componentName: string ) {
    let map = this.converterMap[entityName]?.components[componentName];

    // console.log('this.converterMap', this.converterMap)
    // console.log('dataJson',dataJson);
    // console.log('entityName', entityName);
    // console.log('componentName',componentName);
    // console.log('map', map);
    if(!map){
      console.warn('Error c01')
      return dataJson
    }

    let isKeyString = (str: string): boolean => str[0] === "'" && str[str.length-1] === "'";
    let valueFromKeyString = (str: string): string => str.replace(/'/g, '');
    let valueFromData = (arr:string[], currentIndex = 0, value) => {
      let key = arr[currentIndex];
      let val = value[key];
      if(arr.length === 1){
        return val;
      }
      if (!key && !val){
        return value
      }
      return valueFromData(arr, ++currentIndex, val);
    }

    let c = (json) => {
      let retval = {};
      if (Object.keys(map).length > 0) {
        Object.keys(map).forEach(key => {
          let toRetval = "";
          if (map[key].length === 1){
            if (isKeyString(map[key][0])) {
              toRetval = valueFromKeyString(map[key][0]);
            }else {
              toRetval = valueFromData(map[key][0].split('.'),0, json || {})
            }
          }else {
            map[key].forEach(jsonKey => {
              if (isKeyString(jsonKey)) {
                toRetval += valueFromKeyString(jsonKey);
              }else {
                toRetval += valueFromData(jsonKey.split('.'),0, json || {})
              }
            })
          }
          retval[key] = toRetval
        })
      } else {
        retval = json;
      }
      return retval;
    }
    return Array.isArray(dataJson) ? dataJson.map(el => c(el)) : c(dataJson);
  }
}
