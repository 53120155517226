<div class="list">
  <div class="flexbox">
    <ng-container *ngIf="_data && _data.length > 0">
      <ng-container *ngFor="let element of _data;let i = index">
        <ng-container *ngIf="_elementSelektor">
        <app-dynamic-selector [inputs]="{element: element, sortPosition: i}" [componentSelector]="'bundle.' + _elementSelektor"></app-dynamic-selector>
        </ng-container>
      </ng-container>
    </ng-container>
    <div></div>
  </div>
</div>



