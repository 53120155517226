import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {BaseComponent} from "../../base.component";
import {Subscription} from "rxjs/Subscription";
import {AppReservationCategoryService} from "../../../shared/bundles/app/reservation_category/reservation_category.service";
import {AppTestifyService} from "../../../shared/bundles/app/testify/testify.service";
import {AppOneserviceService} from "../../../shared/bundles/app/oneservice/oneservice.service";
import {AppDaytypeService} from "../../../shared/bundles/app/daytype/daytype.service";
import {AppReservationService} from "../../../shared/bundles/app/reservation/reservation.service";
import {FormFormService} from "../../../services/form.api.service";
import {ActivatedRoute, Params} from "@angular/router";
import {CustomSelectSettingsModel} from "../../form/customselect/customSelectSettingsModel";
import {AppTestify} from "../../../shared/bundles/app/testify/testify.model";
import {Filter} from "../../../shared/bundles/core/utils/filter/filter.model";
import {AppDaytype} from "../../../shared/bundles/app/daytype/daytype.model";
import {ApiLink, getLocale} from "../../../utils/utils";
import {AppReservationCategory} from "../../../shared/bundles/app/reservation_category/reservation_category.model";
import {AppOneservice} from "../../../shared/bundles/app/oneservice/oneservice.model";

// import {BaseComponent} from '../../../core/base.component';
// import {StructurePageService} from '../../../shared/bundles/structure/page/page.service';
// import {ReservationCategoryService} from '../../../shared/bundles/reservation/category/category.service';
// import {Subscription} from 'rxjs/Subscription';
// import {ActivatedRoute, Params} from '@angular/router';
// import {CoreMessage} from '../../../shared/bundles/core/message/message.model';
// import {Filter} from '../../../shared/bundles/core/utils/filter/filter.model';
// import {CoreCmsElement} from '../../../shared/bundles/core/cmselement/cmselement.custom.model';
// import {ReservationCategory} from '../../../shared/bundles/reservation/category/category.model';
// import {ReservationTestifyService} from '../../../shared/bundles/reservation/testify/testify.service';
// import {ReservationTestify} from '../../../shared/bundles/reservation/testify/testify.model';
// import {ReservationDayTypeService} from '../../../shared/bundles/reservation/daytype/daytype.service';
// import {ReservationDayType} from '../../../shared/bundles/reservation/daytype/daytype.model';
// import {ReservationReservationService} from '../../../shared/bundles/reservation/reservation/reservation.service';
// import {ReservationReservation} from '../../../shared/bundles/reservation/reservation/reservation.model';
// import {FormForm} from '../../../shared/bundles/form/form/form.model';
// import {FormFormService} from '../../../shared/bundles/form/form/form.service';
// import {CustomSelectSettingsModel} from '../../form/customselect/customSelectSettingsModel';
// import {FormField} from '../../../shared/bundles/form/field/field.model';
// import {ReservationOneServiceService} from '../../../shared/bundles/reservation/oneservice/oneservice.service';
// import {ReservationOneService} from '../../../shared/bundles/reservation/oneservice/oneservice.model';


@Component({
    selector: 'bundle.reservation',
    templateUrl: './bundle.reservation.reservation.component.html',
    styleUrls: ['./bundle.reservation.reservation.component.scss'],
})

export class BundleReservationReservationComponent extends BaseComponent implements OnInit, OnDestroy {

    settings: any;

    paramsSubscription: Subscription;

    component: string;

    routerLink: string;
    categoryList: any;
    categoryListOptions: any;
    testifyList: any;
    testifyListOptions: any;
    instanceserviceList: any;
    oneserviceListOptions: any;
    dayList: any;

    step: number;
    stepCounter: number;
    isEmailConfirm: boolean;
    selectedCategory: number;
    selectedTestify: number;
    harmonogramType: string;
    harmonogram: any;


    dateFrom: Date;
    dateTo: Date;
    dateSeparator: string;
    dateCurrent: Date;
    dateToday: Date;

    testifyForReservation: any;
    reservationAtForReservation: string;
    oneSelectedService: any;

    serviceList: any;
    serviceListOptions: any;
    serviceListForShow: any;

    constructor(protected injector: Injector,
                private reservationCategoryService: AppReservationCategoryService,
                private reservationTestifyService: AppTestifyService,
                private reservationOneServiceService: AppOneserviceService,
                private reservationDayTypeService: AppDaytypeService,
                private reservationReservationService: AppReservationService,
                private formService: FormFormService,
                private route: ActivatedRoute
    ) {
        super(injector);
    }

    configuration() {
        this.step = 1;
        this.isEmailConfirm = true;
        this.dateInit();
        this.stepCounter = 4;
        this.selectedCategory = -1;
        this.selectedTestify = -1;
    }

    /** Zarządzanie datami **/
    dateInit() {
        this.dateCurrent = new Date(); // current date of week
        this.dateSeparator = '-';
        this.calculateWeekByCurrentDate();
    }

    calculateWeekByCurrentDate() {
        var currentWeekDay = this.dateCurrent.getDay();
        var lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
        this.dateFrom = new Date(new Date(this.dateCurrent).setDate(this.dateCurrent.getDate() - lessDays));
        this.dateTo = new Date(new Date(this.dateFrom).setDate(this.dateFrom.getDate() + 6));
    }

    dateNext() {
        this.dateCurrent = new Date(new Date(this.dateCurrent).setDate(this.dateCurrent.getDate() + 7));
        this.calculateWeekByCurrentDate();
        this.getHarmonogram();
    }


    datePrev() {
        var today = new Date();
        var currentWeekDay = today.getDay();
        var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
        var wkStart = new Date(new Date(today).setDate(today.getDate() - lessDays));

        var dateToSetCurrent = new Date(new Date(this.dateCurrent).setDate(this.dateCurrent.getDate() - 7));

        if (dateToSetCurrent <= wkStart) {
            // this.dateCurrent = wkStart;
        } else {
            this.dateCurrent = dateToSetCurrent;
            this.calculateWeekByCurrentDate();
            this.getHarmonogram();
        }
    }


    ngOnInit() {

      this.settings = new CustomSelectSettingsModel(true,false);

        this.serviceListForShow = null;
        this.serviceList = {'category': [], 'testify': []};
        this.testifyListOptions = [];
        this.categoryListOptions = [];
        this.oneserviceListOptions = [];

        var tthis = this;

        this.paramsSubscription = this.route.queryParams.subscribe(
            (params: Params) => {

                if (params['a'] === 'confirm') {
                    this.component = 'confirm';
                } else if (params['a'] === 'decline') {
                    this.component = 'decline';
                } else {
                    this.component = 'reservation';

                    this.configuration();

                    // let filter = new Filter();
                    // filter.addOrder('sortPosition', 'a');
                    this.reservationOneServiceService.getAll().subscribe((oneserviceList: Array<AppOneservice>) => {
                      this.oneserviceListOptions = oneserviceList;
                      this.serviceListForShow = oneserviceList;
                    });

                    this.reservationCategoryService.getAll().subscribe((categoryList: Array<AppReservationCategory>) => {
                        this.categoryList = categoryList;
                        tthis.categoryListOptions.push({'key': -1, 'value': getLocale('reservation_step_1_select_category')});
                        this.categoryList.forEach(function(category){
                            if (category.reservationCategoryServices && category.reservationCategoryServices.length > 0) {
                                tthis.serviceList['category'][category.id] = category.reservationCategoryServices;
                            }
                            tthis.categoryListOptions.push({'key': category.id, 'value': category.name});
                        });

                        this.reservationTestifyService.getAll().subscribe((testifyList: Array<AppTestify>) => {

                            this.testifyList = testifyList
                              // .filter(function (el: AppTestify) {
                              //   return el.reservationTestifyCategory != null;
                            // });
                          tthis.testifyListOptions.push({'key': -1, 'value': getLocale('reservation_step_1_select_testify')});

                            this.testifyList.forEach(function(testify){
                                if (testify.reservationTestifyServices && testify.reservationTestifyServices.length > 0) {
                                    tthis.serviceList['testify'][testify.id] = testify.reservationTestifyServices;
                                }
                                var fullName = testify.name;
                                if(testify.reservationTestifyCategory && testify.reservationTestifyCategory.name)
                                {
                                  fullName+= ' - ' + testify.reservationTestifyCategory.name
                                }
                                tthis.testifyListOptions.push({'key': testify.id, 'value': fullName });
                            });

                            if(this.categoryList.length < 2 && this.testifyList.length === 1 && this.oneserviceListOptions.length <= 1)
                            {
                                this.setectTestify(this.testifyList[0].id);
                            }
                        });



                    });



                    this.reservationDayTypeService.getAll().subscribe((dayList: Array<AppDaytype>) => {
                        this.dayList = dayList;
                    });

                    this.scrollToReservation();
                    this.route.params
                        .subscribe(
                            (params: Params) => {
                                this.routerLink = '';
                                var language = this.route.snapshot.params['language'];
                                if (language) {
                                    this.routerLink += '/' + language;
                                }
                                var i;
                                for (i = 0; i < 10; i++) {
                                    var key = '';
                                    if (i > 0) {
                                        key = i.toString();
                                    }

                                    var path = this.route.snapshot.params['page' + key];
                                    if (path) {
                                        this.routerLink += '/' + path;
                                    }
                                }
                            });

                }
            }
        );

    }

    scrollToReservation()
    {
        // document.getElementsByClassName('breadcrumb_content')[0].scrollIntoView();
    }

    setectCategory(selectedItem) {
      this.selectedCategory = selectedItem;
        this.scrollToReservation();
        this.selectedTestify = null;
        this.serviceListForShow = null;
        this.harmonogramType = 'byCategory';
        // console.log(this.serviceList['category']);
        // console.log(this.serviceList['category'][this.selectedCategory]);
        if (this.serviceList['category'].length <= 0 || !this.serviceList['category'][this.selectedCategory]) {
            this.selectService();
        }else{
            this.serviceListForShow = this.serviceList['category'][this.selectedCategory];
        }
    }

    setectTestify(selectedItem) {
        this.selectedTestify = selectedItem;
        this.scrollToReservation();
        this.selectedCategory = null;
        this.serviceListForShow = null;
        this.harmonogramType = 'byTestify';

        if ((this.serviceList['testify'].length <= 0 || !this.serviceList['testify'][this.selectedCategory])) {
            this.selectService();
        }else{
            this.serviceListForShow = this.serviceList['testify'][this.selectedCategory];
        }

    }

  goToStep1()
  {
    document.querySelector('body').classList.remove('nextStep');
    this.step = 1;
  }

    selectService(service = null)
    {
        document.querySelector('body').classList.add('nextStep');
        this.oneSelectedService = service;
        this.step = 2;
        this.getHarmonogram();
    }

    changeTerm() {
        this.scrollToReservation();
        this.testifyForReservation = null;
        this.reservationAtForReservation = null;
        this.step = 2;
    }

    isEmptyWeek(week)
    {
      let isEmpty = true;
      if(week.length > 0)
      {
        week.forEach(function(el){
          if(el.harmonogram && el.harmonogram.length >0)
          {
            isEmpty = false;
          }
        });
      }

      return isEmpty;
    }

    termForReservation(testify: any, term: string) {
        this.scrollToReservation();
        var oneTestify = this.testifyList.filter(function (el: AppTestify) {
            return el.id === testify.id;
        });
        console.log('oneTestify[0]',oneTestify[0])
        if(oneTestify)
        {
            this.testifyForReservation = oneTestify[0];
            this.reservationAtForReservation = term;
            this.step = 3;
        }
    }

    getHarmonogram() {
        var filer = new Filter();

        this.clearHarmonogram();
        if (this.harmonogramType === 'byCategory') {
            var id = this.selectedCategory;
            filer.addWhere('category', '=', id);
        }
        if (this.harmonogramType === 'byTestify') {
            var id = this.selectedTestify;
            filer.addWhere('testify', '=', id);
        }

        var month = this.dateCurrent.getMonth() + 1;
        var monthString = '';
        if (month < 10) {
            monthString = '0' + month.toString();
        } else {
            monthString = month.toString();
        }


        //category=${this.selectedCategory}&testify=${this.selectedTestify}
        filer.addWhere('day', '=', this.dateCurrent.getFullYear() + '-' + monthString + '-' + this.dateCurrent.getDate());
        let params = `?&day=${this.dateCurrent.getFullYear() + '-' + monthString + '-' + this.dateCurrent.getDate()}`
        // this.reservationReservationService.getAll(params)
        this.apiService.getByStaticUrl(ApiLink(`/harmonogram${params}`)).subscribe((harmonogramList: any) => {
              this.harmonogram = harmonogramList;
        });
    }


    /**
     * Czyszczenie siatki i wyborów
     */
    clearHarmonogram() {
        this.harmonogram = null;
    }

    ngOnDestroy() {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }



}
