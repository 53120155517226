import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
// import {BaseComponent} from '../../../core/base.component';
// import {StructurePageService} from '../../../shared/bundles/structure/page/page.service';
// import {GalleryGalleryService} from '../../../shared/bundles/gallery/gallery/gallery.service';
// import {GalleryPhoto} from '../../../shared/bundles/gallery/photo/photo.model';
// import {ReservationPlace} from '../../../shared/bundles/reservation/place/place.model';

@Component({
    selector: 'bundle.gallery-slick',
    templateUrl: './bundle.gallery.slick.component.html',
    styleUrls: ['./bundle.gallery.slick.component.scss'],
})

export class BundleGallerySlickComponent extends BaseComponent implements OnInit {
    // galleryObj;
    //
    // slideConfig = null;
    //
    // @Input()
    // set gallery(obj){
    //   this.data = obj
    //
    //   console.log('obj',obj);
    //   // this.galleryObj = obj;
    //   this.slideConfig = null;
    //   // this.setSlickConfig(this.galleryObj.galleryGalleryPhotos.length > 9 ? 9 : this.galleryObj.galleryGalleryPhotos.length)
    // }
    //
    // constructor(public structurePageService: StructurePageService, private galleryService: GalleryGalleryService) {
    //     super(structurePageService);
    // }
    //
    ngOnInit() {
    }

  protected onSetData(data: any = this._data) {
    console.log('BundleGallerySlickComponent', data)

      super.onSetData(data);
  }


  // getPhotoName(photo) {
    //     if (photo && photo.name) {
    //         return photo.name;
    //     }
    //     return '';
    // }

    // setSlickConfig(maxElements = 9) {
    //   this.slideConfig = {
    //     'slidesToShow': maxElements,
    //     'slidesToScroll': 1,
    //     'fade': false,
    //     'autoplay': false,
    //     'dots': false,
    //     'arrows': false,
    //     'responsive': [{
    //       'breakpoint': 1200,
    //       'settings': {
    //         "slidesToShow": 6,
    //         "fade": false,
    //         "slidesToScroll": 1,
    //         'autoplay': false,
    //         'dots': true,
    //       }
    //     }, {
    //       'breakpoint': 768,
    //       'settings': {
    //         "slidesToShow": 4,
    //         "fade": false,
    //         "slidesToScroll": 1,
    //         'autoplay': false,
    //         'dots': true,
    //       }
    //     },{
    //       'breakpoint': 480,
    //       'settings': {
    //         "slidesToShow": 2,
    //         "fade": false,
    //         "slidesToScroll": 1,
    //         'autoplay': false,
    //         'dots': true,
    //       }
    //     }]
    //   };
    // }


}
