import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../bundles/base.component";


@Component({
    selector: 'app-core-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})

export class RatingComponent extends BaseComponent implements OnInit {

    @Input() amountOfStars: number = 5;
    _currentValue: number

    @Input()
    set currentValue(value:any) {
      if (!isNaN(parseFloat(value))) {
        this._currentValue = parseFloat(value);
      }
    }

    get currentValue() {
      return this._currentValue
    }


    @Input() makeRating = false;
    @Input() halfStar: boolean = false;

    stars: any;
    randId: number;


    ngOnInit() {
        this.randId = Math.floor((Math.random() * 1000000) + 1);
        this.stars = [];
        var i = this.amountOfStars;

        var type = 'full';
        this.stars.push({'number': i, 'type': type});

        var minValue = 1;
        if (this.halfStar) {
            minValue = 0.5;
        }

        while (i > minValue) {
            var type = 'full';
            if (this.halfStar) {
                i = i - 0.5;
                if (Math.round(i) !== i) {
                    type = 'half';
                }
            } else {
                i--;
            }
            this.stars.push({'number': i, 'type': type});

        }
    }

    public onItemChange(star) {

    }

}
