import {Component, Injector} from '@angular/core';
import {CMSPageService} from '../../shared/bundles/cms/page/page.service';
import {CookieService} from 'ngx-cookie-service';
import {BaseComponent} from '../../bundles/base.component';

@Component({
    selector: 'bundle.core-cookiepopup',
    templateUrl: './cookiepopup.component.html',
    styleUrls: ['./cookiepopup.component.scss'],
    providers: []
})

export class CookiepopupComponent extends BaseComponent {

    showPopup: boolean;

    constructor(
      protected _injector: Injector,
      private cookieService: CookieService,
    ) {
        super(_injector);
        var cookie = this.cookieService.get('cookie_accept');
        if (!cookie) {
            this.showPopup = true;
        } else {
            this.showPopup = false;
        }

    }

    acceptCookies(): void {
        this.cookieService.set('cookie_accept', '1', 180, '/');
        this.showPopup = false;
    }

}
