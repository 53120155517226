import {Component, Input, OnInit, ViewChild} from '@angular/core';
// import {FilecontentService} from '../../shared/bundles/core/filecontent/filecontent.service';
import {Subject} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {ApiLink} from "../../utils/utils";

@Component({
  selector: 'app-core-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  providers: []
})

export class SvgComponent {
  _url: any = new Subject<string>();
  _customStyle: any = new Subject<string>();

  @Input() set url(val: string) {
    if (this._urlStatic){
      if (this._urlStatic !== val) {
        this._urlStatic = val;
        this._url.next(val);
      }
    }else {
      this._urlStatic = val;
      this._url.next(val);
    }
  }

  @Input() set customStyle(val: any) {
    this._customStyle.next(val);
  }

  protected _urlStatic;

  plainText: string = '';
  @Input() preserve: boolean = false;
  @ViewChild('container') containerToSvg;

  constructor(
    protected apiService: ApiService
  ) {
    this._url.subscribe(value => {
      this.apiService.getPlainText(ApiLink(value, true)).subscribe((data:string) => {
        // this.plainText = data;
        // let text = data;
        this.plainText = this.classStylesToInline(data);

        if (this.preserve) {
          this.plainText = this.plainText.replace('<svg', '<svg preserveAspectRatio="none"');

          this._customStyle.subscribe(customStyle => {
            var styleArray = customStyle;

            var fillString = '';
            if (styleArray.fillColor) {
              fillString = 'fill: ' + styleArray.fillColor + ' ';
            }

            var strokeString = '';
            if (styleArray.strokeColor) {
              strokeString = 'stroke: ' + styleArray.strokeColor + ' ';
            }

            var styleString = '';
            if (styleArray.style) {
              styleString += styleArray.style.join(' ') + ' ';
            }

            this.plainText = this.plainText.replace(/<path /g, '<path style="' + fillString + styleString + '" ')
              .replace(/<rect /g, '<rect style="' + fillString + styleString + '" ')
              .replace(/<polygon /g, '<polygon style="' + fillString + styleString + '" ')
              .replace(/<circle /g, '<circle style="' + strokeString + styleString + '" ')
              .replace(/<line /g, '<line style="' + strokeString + styleString + '" ');
          });
        }
      });
    });
  }

  protected classStylesToInline(text: string) {
    try {
      let template = document.createElement('template');
      template.innerHTML = text.trim();
      let element = template.content.firstChild as HTMLElement;
      let styles = element.querySelector('defs > style');
      Array.from(styles.childNodes).forEach(childNode => {
        childNode.textContent.trim().split('}')
          .map(el =>
            el.replace(/}/g, '')
              .replace(/\n/g, '')
              .replace(/\s*/g, '')
              .replace(/;/g, '')
              .trim()
              .split('{')
          )
          .filter(el => el[0] !== '')
          .forEach(el => {
            let getExistStyles = (element) => element.getAttribute('style') ? element.getAttribute('style').split(';') : [];
            Array.from(element.querySelectorAll(el[0])).forEach(element => {
              let styles = getExistStyles(element).concat([el[1]]);
              element.setAttribute('style', styles.join(';'));
            });
          });
      });
      return template.innerHTML;
    } catch (err) {
      return text;
    }
  }

}
