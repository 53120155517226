import {Component, Input} from '@angular/core';
import {BaseComponent} from "../../base.component";
import Article from "./bundle.article.article.component.interface";


@Component({
    selector: 'bundle.article',
    templateUrl: './bundle.article.article.component.html',
    styleUrls: ['./bundle.article.article.component.scss'],
})

export class BundleArticleArticleComponent extends BaseComponent{

  _data: Article

}
