<ng-container *ngIf="formForm">
  <!--  formForm?.formKey, form.value-->
  <form #form="ngForm" action="{{urlSend}}" (ngSubmit)="submitPost(form, $event, form.value)" enctype="multipart/form-data"
        class="form {{formForm.formName}}" id="generatedForm_{{formForm?.formKey}}">
    <span class="name" *ngIf="formForm?.formName">{{formForm?.formName}}</span>
    <div class="description" *ngIf="formForm?.formDescription" innerHtml="{{formForm?.formDescription}}"></div>
    <app-core-message *ngIf="message !== undefined && message" [message]="message"></app-core-message>
    <div class="formSections formGroups{{formForm?.sections.length}}">
      <ng-container *ngFor="let formSection of formForm?.sections">
        <div class="formGroup">
          <span *ngIf="formSection?.name" class="header">{{formSection?.name}}</span>
          <span class="sectionDescription" *ngIf="formSection?.description" innerHtml="{{formSection?.description}}"></span>

          <div class="flexbox">
            <ng-container *ngFor="let formField of formSection?.fields">
              <div class="form_component component_{{formField?.fieldType}}" id="component_{{formForm?.id}}_field_{{formField?.textLabel}}"
                   [ngStyle]="{'flex': '0 0 '+formField.fieldWidth+'%'}">

<!--                &lt;!&ndash;                                <ng-container *ngIf="formField?.bindEventsJS && formField?.bindEventsJS?.length>0 && formField?.fieldType == 'checkbox'">&ndash;&gt;-->
<!--                &lt;!&ndash;                                    <div class="input_checkbox">&ndash;&gt;-->
<!--                &lt;!&ndash;                                        <input *ngIf="formField?.fieldType == 'checkbox'" (click)="clickEvent(formForm?.formKey, formField?.fieldKey)" id="generatedForm_{{formForm?.formKey}}_field_{{formField?.fieldKey}}" type="checkbox" value="1" name="{{formField?.fieldKey}}" [(ngModel)]="formField.defaultValue">&ndash;&gt;-->
<!--                &lt;!&ndash;                                        <label *ngIf="formField?.fieldType == 'checkbox'" for="generatedForm_{{formForm?.for-->
<!--                                                            <ng-container *ngIf="formField?.validatorType"><span class="isReq" *ngIf="!formForm?.hideRequiredStars">*</span></ng-container>-->
<!--                &lt;!&ndash;                                        </label>&ndash;&gt;-->
<!--                &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                &lt;!&ndash;                                </ng-container>&ndash;&gt;-->


                <!--       LABELEK DO INPUTA         -->
                <label
                  *ngIf="(( formField?.fieldType == 'stringSelect' && formField?.label && formField?.fieldOptions && formField?.fieldOptions?.length>0) || (formField?.fieldType != 'stringSelect' && formField?.textDescription)) && formField?.fieldType != 'submit' && formField?.fieldType != 'hidden' && formField?.fieldType != 'a' && formField?.fieldType != 'checkbox'"
                  for="{{generateId(formForm?.formKey, formField?.formFieldName)}}">
                  <ng-container
                    *ngIf="formField?.fieldType != 'submit' && formField?.fieldType != 'checkbox' && formField?.textDescription">{{formField?.textDescription}}
                    :
                  </ng-container>
                  <ng-container
                    *ngIf="formField?.fieldType == 'submit' || formField?.fieldType == 'checkbox'">{{formField?.textDescription}}</ng-container>
                  <ng-container *ngIf="formField?.validationType"><span class="isReq" *ngIf="!formForm?.hideRequiredStars">*</span>
                  </ng-container>
                  <!--<ng-container *ngIf="formField?.description"><span class="info">i</span></ng-container>-->
                </label>

                <!--                                <ng-container *ngIf="formField?.bindEventsJS">-->
                <!--                                    <ng-container *ngIf="formField?.fieldOptions && formField?.fieldType == 'radio'">-->
                <!--                                    <span *ngFor="let fieldOption of formField?.fieldOptions ">-->
                <!--                                        <input type="radio" (click)="clickEvent(formForm?.formKey, formField?.fieldKey, fieldOption.key)" name="{{formField?.fieldKey}}" id="generatedForm_{{formForm?.formKey}}_field_{{formField?.fieldKey}}_{{fieldOption.key}}" value="{{fieldOption.key}}" [(ngModel)]="formField.defaultValue" >-->
                <!--                                        <label for="generatedForm_{{formForm?.formKey}}_field_{{formField?.fieldKey}}_{{fieldOption.key}}">{{fieldOption.label}}</label>-->
                <!--                                    </span>-->
                <!--                                    </ng-container>-->
                <!--                                    <span *ngIf="!formField?.fieldOptions && formField?.fieldType == 'radio'">-->
                <!--                                        <app-core-message *ngIf="messageNoOptions !== undefined && messageNoOptions" [message]="messageNoOptions"></app-core-message>-->
                <!--                                    </span>-->
                <!--                                </ng-container>-->

                <!--                <ng-container *ngIf="formField?.fieldOptions && formField?.fieldType == 'radio'">-->
                <!--                                    <span *ngFor="let fieldOption of formField?.fieldOptions ">-->
                <!--                                        <input type="radio" name="{{formField?.formFieldName}}"-->
                <!--                                               id="generatedForm_{{formForm?.formKey}}_field_{{formField?.formFieldName}}_{{fieldOption.key}}"-->
                <!--                                               value="{{fieldOption.key}}" [(ngModel)]="formField.defaultValue">-->
                <!--                                        <label-->
                <!--                                          for="generatedForm_{{formForm?.formKey}}_field_{{formField?.formFieldName}}_{{fieldOption.key}}">{{fieldOption.label}}</label>-->
                <!--                                    </span>-->

                <!--                </ng-container>-->


                <!--                                <ng-container *ngIf="formField?.fieldOptions && formField?.fieldType == 'stringSelect'">-->
                <!--                                    <span *ngFor="let fieldOption of formField?.fieldOptions" (click)="stringSelect(formForm?.formKey, formField?.fieldKey, fieldOption.key)">-->
                <!--                                        {{fieldOption.label}}-->
                <!--                                    </span>-->
                <!--                                </ng-container>-->

                <!--                                <input *ngIf="formField?.fieldType == 'hidden'"  id="generatedForm_{{formForm?.formKey}}_field_{{formField?.fieldKey}}" type="hidden" value="" name="{{formField?.fieldKey}}" [(ngModel)]="formField.defaultValue">-->

                <!--                              generatedForm_{{formForm?.formKey}}_field_{{formField?.formFieldName}}-->

                <!--      CHECKBOX          -->
                <ng-container *ngIf="formField?.fieldType == 'checkbox'">
                  <div class="input_checkbox">
                    <input *ngIf="formField?.fieldType == 'checkbox'" id="{{generateId(formForm?.formKey, formField?.formFieldName)}}"
                           type="checkbox" value="1" name="{{formField?.formFieldName}}">
                    <label *ngIf="formField?.fieldType == 'checkbox'" for="{{generateId(formForm?.formKey, formField?.formFieldName)}}">
<!--                      {{formField?.textDescription  }}-->
                      <span *ngIf="formField?.textDescription" [innerHtml]="checkboxDescription(formField)"></span>

                      <ng-container *ngIf="formField?.validationType">
                        <span class="isReq" *ngIf="!formForm?.hideRequiredStars">*</span>
                      </ng-container>
<!--                      <br *ngIf="formField?.description">-->
<!--                      <span *ngIf="formField?.description" [innerHtml]="checkboxDescription(formField)"></span>-->
                    </label>
                  </div>
                </ng-container>

                <!--      RADIO          -->
                <ng-container *ngIf="formField?.fieldOptions && formField?.fieldType == 'radio'">
                    <span *ngFor="let fieldOption of formField?.fieldOptions ">
                        <input type="radio" [name]="formField?.formFieldName"
                               id="generatedForm_{{formForm?.formKey}}_field_{{formField?.formFieldName}}_{{fieldOption.key}}"
                               value="{{fieldOption.key}}" >
                        <label
                          for="generatedForm_{{formForm?.formKey}}_field_{{formField?.formFieldName}}_{{fieldOption.key}}">{{fieldOption.label}}</label>
                    </span>
                </ng-container>

                <!--        INPUT           -->
                <input *ngIf="formField?.fieldType == 'input'"
                       id="{{generateId(formForm?.formKey, formField?.formFieldName)}}"
                       type="text" [name]="formField?.formFieldName" value="{{formField.defaultValue}}">

                <input *ngIf="formField?.fieldType == 'date'" datepicker
                       id="{{generateId(formForm?.formKey, formField?.formFieldName)}}"
                       type="text" [name]="formField?.formFieldName" value="{{formField.defaultValue}}">

                <ng-container *ngIf="formField?.fieldType == 'datetime'">
                  <input datetimepicker id="{{generateId(formForm?.formKey, formField?.formFieldName)}}"
                         type="text" [name]="formField?.formFieldName" value="{{formField.defaultValue}}">
                </ng-container>

                <!--       PASSWORD         -->
                <input *ngIf="formField?.fieldType == 'password'"
                       id="{{generateId(formForm?.formKey, formField?.formFieldName)}}" type="password"
                       [name]="formField?.formFieldName" value="{{formField.defaultValue}}">

                <!--      TEXTAREA          -->
                <textarea *ngIf="formField?.fieldType == 'textarea'"
                          id="{{generateId(formForm?.formKey, formField?.formFieldName)}}" type="text"
                          [name]="formField?.formFieldName" value="{{formField.defaultValue}}"></textarea>

                <!--        FILE           -->
                <input *ngIf="formField?.fieldType == 'file'" id="{{generateId(formForm?.formKey, formField?.formFieldName)}}" type="file"
                       value="{{formField.defaultValue}}" [name]="formField?.formFieldName" >

                <!--                                <select *ngIf="formField?.fieldType == 'select'"  id="generatedForm_{{formForm?.formKey}}_field_{{formField?.fieldKey}}" name="{{formField?.fieldKey}}" [(ngModel)]="formField.defaultValue">-->
                <!--                                    <ng-container *ngIf="formField?.fieldOptions?.length > 0 ">-->
                <!--                                        <option *ngFor="let fieldOption of formField?.fieldOptions " value="{{fieldOption.key}}">-->
                <!--                                            {{fieldOption.label}}-->
                <!--                                        </option>-->
                <!--                                    </ng-container>-->
                <!--                                </select>-->
                <!--                                <a *ngIf="formField?.fieldType == 'a' && !formField?.fieldOptions.href" [routerLink]="formField?.fieldOptions.url" [queryParams]="formField?.fieldOptions.queryParams" >{{formField?.label}}</a>-->
                <!--                                <a *ngIf="formField?.fieldType == 'a' && formField?.fieldOptions.href" href="{{formField?.fieldOptions.href}}" >{{formField?.label}}</a>-->
                <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                <input *ngIf="formField?.fieldType == 'submit'" id="{{generateId(formForm?.formKey, formField?.formFieldName)}}"
                       type="submit" value="{{formField?.textDescription}}" name="{{formField?.formFieldName}}" class="button">
                <!--                            <input *ngIf="formField?.fieldType == 'hidden'"  id="generatedForm_{{formForm?.formKey}}_field_{{formField?.fieldKey}}" type="hidden" value="" name="{{formField?.fieldKey}}" [(ngModel)]="formField.defaultValue">-->
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="getRef()">
        <input type="hidden" name="ref" value="{{getRef()}}">
      </ng-container>

      <input type="hidden" [name]="'front-form'" value="{{formForm?.id}}">
      <input type="hidden" name="formKey" value="{{formForm?.formKey}}">
    </div>
    <div class="clear"><!-- --></div>

  </form>
</ng-container>
