import {Component, EventEmitter, Input, Output} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {CustomSelectSettingsModel} from './customSelectSettingsModel';
import {CustomSelectOptionModel} from './customSelectOptionModel';

@Component({
  selector: 'form-custom-select',
  templateUrl: './bundle.form.customselect.component.html',
  styleUrls: ['./bundle.form.customselect.component.scss'],
  host: {
    // '(document:click)': 'closeDropdown($event)',
  }
})

export class BundleFormCustomselectComponent {

  @Input() settings: CustomSelectSettingsModel = new CustomSelectSettingsModel(true,false);
  @Input() options: Array<CustomSelectOptionModel> = [];
  @Input() selected: string = '';
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() svg:string

  active: boolean;
  searchTerm: string;
  filterOptions: any;
  selectedItem: CustomSelectOptionModel;
  randId: string;

  constructor() {
  }

  ngOnInit() {

    this.randId = 'customSelect'+Math.floor((Math.random() * 1000000000) + 1)

    this.active = false;

    var selectedObj = this.options.find(x => x.key === this.selected);
    if(selectedObj)
    {
      this.selectedItem = selectedObj;
    }

    this.searchTerm = '';
    if(this.selectedItem){
      this.searchTerm = this.selectedItem.value;
    }

    this.filterOptions = Object.assign([], this.options);
  }

  search() {
    if (this.searchTerm === "") {
      this.filterOptions = Object.assign([], this.options);
      return;
    }
    var reg = new RegExp(this.searchTerm, 'gi');
    this.filterOptions = this.options.filter(function (elem) {
      if (reg.test(elem.value)) {
        return elem;
      }
    });
  }

  changeValue(key) {
    var selectedObj = this.options.find(x => x.key === key);
    if (selectedObj) {
      this.searchTerm = selectedObj.value;
    }
    this.selectedItem = selectedObj;
    if (this.settings && !this.settings.returnKey) {
      this.onChange.emit(this.selectedItem.value);
    }
    else {
      this.onChange.emit(key);
    }
  }

  closeDropdown(event){
      if(!(<HTMLElement>document.getElementById(this.randId)).contains(event.target))
      {
        this.active = false;
        if( this.selectedItem){
          this.searchTerm = this.selectedItem.value;
        }else{
          this.searchTerm = '';
        }

        this.filterOptions = Object.assign([], this.options);
      }
    }
}
