import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {BaseComponent} from '../../base.component';
import News from "./bundle.news-list-slick.component.interface";

@Component({
  selector: 'bundle.news-list-slick',
  templateUrl: './bundle.news-list-slick.component.html',
  styleUrls: ['./bundle.news-list-slick.component.scss'],
  providers: []
})

export class BundleNewsListSlickComponent extends BaseComponent implements OnInit{

  public _data: News[];

  ngOnInit(): void {}

}
