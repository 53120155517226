import {Component, Injector, Input, OnInit, Output} from '@angular/core';
import {CoreLanguageService} from '../../shared/bundles/core/language/language.service';
import {AppConfig} from '../../config/app.config';
import {BaseComponent} from '../../bundles/base.component';
import {AppVariables} from '../../config/app.variables';
import {CoreLanguage} from '../../shared/bundles/core/language/language.model';
import {Router} from '@angular/router';
import {CustomSelectOptionModel} from '../../bundles/form/customselect/customSelectOptionModel';


@Component({
  selector: 'bundle.pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers: []
})
export class PaginationComponent extends BaseComponent implements OnInit {

  @Input()
  set totalElements(totalElements) {
    console.log('setTotal', totalElements)
    this._totalElements = totalElements;
    this.paginationArr = this.getToDisplay();
  }

  @Input()
  set itemsPerPage(itemsPerPage) {
    this._itemsPerPage = itemsPerPage;
    this.paginationArr = this.getToDisplay();
  }

  _totalElements = 0;
  _itemsPerPage = 2;

  //Wyświetla ilość elementów przed '...';
  maxDisplayed = 3

  currentPage = 1;

  paginationArr = [];

  ngOnInit() {
    this._itemsPerPage = AppVariables.pagination.default;
    this.urlService.urlParams.subscribe(params => {
      // Obsługa ilości elementów na stronie
      if (params['itemsPerPage']){
        if (!isNaN(params['itemsPerPage'])){
          this._itemsPerPage = parseInt(params['itemsPerPage']);
        }else {
          this.urlService.removeParametr('itemsPerPage');
        }
      }

      // Obsługa strony
      if (params['page']){
        if (!isNaN(params['page'])){
            this.currentPage = parseInt(params['page']);
        }else {
          this.urlService.addParametr('page', this.currentPage);
        }
      }
      this.paginationArr = this.getToDisplay();
    })
  }

  getAmountOfPages(){
    console.log('here',this._totalElements,this._itemsPerPage, Math.ceil(this._totalElements/this._itemsPerPage))
    return Math.ceil(this._totalElements/this._itemsPerPage);
  }

  arrayFromNumber(number, iterateFrom = 1) {
    let retval = [];
    for(let i = 0; i < number; i++){
      retval.push(i+iterateFrom);
    }
    return retval;
  }

  getToDisplay() {
    let amountOfPages = this.getAmountOfPages();
    let retval = [];

    // console.log('-------------')
    // console.log('amountOfPages', amountOfPages);
    // console.log('this.currentPage', this.currentPage);
    // console.log('totalEleemnts', this._totalElements);
    // console.log('this.maxDisplayed', this.maxDisplayed)
    // console.log('-------------')

    // console.log(this.currentPage, this._totalElements)
    // console.log(amountOfPages, this.maxDisplayed)
    if (amountOfPages < this.maxDisplayed){
      retval = this.arrayFromNumber(amountOfPages)
    }else {
      if (this.currentPage - this.maxDisplayed < 0){
        retval = [...this.arrayFromNumber(this.maxDisplayed),"...", amountOfPages]

      } else if (this.currentPage > amountOfPages - this.maxDisplayed + 1){
        retval = [1,"...", ...this.arrayFromNumber(this.maxDisplayed, amountOfPages-this.maxDisplayed+1)]
      }else {
        if (this.currentPage+2 === amountOfPages){
          retval = [1, "...", ...this.arrayFromNumber(4, this.currentPage-1)]
        }else if(this.currentPage-2 === 1){
          retval = [...this.arrayFromNumber(4, 1), "...", amountOfPages]

        } else {
          retval = [1, "...", ...this.arrayFromNumber(this.maxDisplayed, this.currentPage - 1), "...", amountOfPages]
        }
      }
    }
    console.log('getToDiplay', retval);
    return retval;
  }

  isNumber(element) {
    return typeof element === 'number'
  }

  changePage(element:any){
    if (this.currentPage != element){
      this.urlService.addParametr('page', element);
    }
  }

  prevPage(): void {
    if(this.currentPage > 1){
      this.urlService.addParametr('page', this.currentPage-1)
    }
  }

  nextPage(): void {
    console.log(this.currentPage, this.paginationArr.length)
    if (this.currentPage < this.getAmountOfPages()){
      this.urlService.addParametr('page', this.currentPage+1)
    }
  }

}
