import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'bundle.list-center',
  templateUrl: './bundle.list-center.component.html',
  styleUrls: ['./bundle.list-center.component.scss'],
  providers: []
})

export class BundleListCenterComponent implements OnInit{

    _elements = [];
    _elementSelektor

    @Input()
    set elementSelektor(elementSelektor) {
      this._elementSelektor = elementSelektor
    }

    @Input()
    set elements(elements) {
      console.log('list bubndle', elements);
      this._elements = elements
    }

    constructor() {
    }

    ngOnInit() {
    }
}
