import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {AppOpinionService} from "../../../shared/bundles/app/opinion/opinion.service";


@Component({
    selector: 'bundle.opinion',
    templateUrl: './bundle.opinion.component.html',
    styleUrls: ['./bundle.opinion.component.scss'],
})

export class BundleOpinionComponent extends BaseComponent implements OnInit {

    slideConfig: any;
    placeID = null;
    opinions = [];

    opinionsService = this._injector.get(AppOpinionService);

    @Input()
    set placeId(placeId){
      this.opinions = [];
      this.placeID = placeId;
      this.getOpinions();
    }


    ngOnInit() {
    }

    public getOpinions() {
      this.opinionsService.getAll().subscribe(opinions => {
        console.log(opinions)
        this.opinions = opinions.filter(opinion => {
          return opinion.place.find(el => el.id == this.placeID);
        });
      })
    }

}
